export const TagTypesMap = {
  Customer: "Customer",
  Plan: "Plan",
  Business: "Business",
  User: "User",
  Subscription: "Subscription",
  SubscriptionPayments: "SubscriptionPayments",
  Team: "Team",
  Leads: "Leads",
} as const;

export const TagTypes = Object.values(TagTypesMap);
