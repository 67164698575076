import { Subscription, SubscriptionWithPlanDetails } from "types/Subscription";
import {
  SubscriptionMetrics,
  SubscriptionWithPlanAndCustomerWithMemberAndMemberDetailsList,
} from "services/types/subscription.types";
import { TagTypesMap } from "services/constants";
import { apiSlice } from "./api.slice";

const subscriptionSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createSubscription: builder.mutation<
      Subscription,
      {
        price: number;
        startDate: string;
        endDate: string;
        planId: number;
        customerId: number;
      }
    >({
      query: (body) => ({
        url: "/subscription",
        method: "POST",
        data: body,
      }),
      invalidatesTags: [TagTypesMap.Subscription],
    }),
    getSubscriptions: builder.query<
      SubscriptionWithPlanDetails[],
      { customerId?: number }
    >({
      query: ({ customerId }) => ({
        url: "/subscription",
        params: {
          customerId,
        },
        method: "GET",
      }),
      providesTags: [TagTypesMap.Subscription],
    }),
    cancelSubscription: builder.mutation<
      Subscription,
      { customerSubscriptionId: number }
    >({
      query: ({ customerSubscriptionId }) => ({
        url: "/subscription/cancel",
        method: "POST",
        data: { customerSubscriptionId },
      }),
      invalidatesTags: [TagTypesMap.Subscription],
    }),
    getSubscriptionMetrics: builder.query<SubscriptionMetrics, void>({
      query: () => ({
        url: "/subscription/metrics",
        method: "GET",
      }),
      providesTags: [TagTypesMap.Subscription],
    }),
    getExpiringSubscriptions: builder.query<
      SubscriptionWithPlanAndCustomerWithMemberAndMemberDetailsList,
      void
    >({
      query: () => ({
        url: "/subscription/expiring-soon",
        method: "GET",
      }),
      providesTags: [TagTypesMap.Subscription],
    }),
    sendInvoiceToCustomer: builder.mutation<
      { message: string },
      { customerSubscriptionId: number }
    >({
      query: ({ customerSubscriptionId }) => ({
        url: "/invoices/generate",
        method: "POST",
        data: {
          customerSubscriptionId,
        },
      }),
    }),
  }),
});

export const {
  useCreateSubscriptionMutation,
  useLazyGetSubscriptionsQuery,
  useCancelSubscriptionMutation,
  useGetSubscriptionMetricsQuery,
  useGetExpiringSubscriptionsQuery,
  useSendInvoiceToCustomerMutation,
} = subscriptionSlice;
