import { read, utils } from "xlsx";
import { Headers } from "app/modules/Members/BulkImport/constants";
import { SelectedMemberDetails } from "app/modules/Members/BulkImport/types";
import { isEmail } from "lib/utils/validators/isEmail";
import { isPhoneNumber } from "lib/utils/validators/isPhoneNumber";

const processXlsxFile = async (file: File) => {
  const data = await file.arrayBuffer();

  const workbook = read(data);

  const sheetName = workbook.SheetNames[0];
  const sheet = workbook.Sheets[sheetName];

  const rows = utils.sheet_to_json(sheet, { header: 1 });

  return rows;
};

const validateUploadedFile = (
  data: Array<Array<unknown>>
): Promise<string | void> => {
  if (data.length === 0) {
    return Promise.reject("File is empty");
  }

  const headers = data[0];

  if (headers.length !== Object.keys(Headers).length) {
    return Promise.reject("Invalid file headers");
  }

  if (
    headers[0] !== Headers.NAME ||
    headers[1] !== Headers.GENDER ||
    headers[2] !== Headers.CONTACT ||
    headers[3] !== Headers.EMAIL
  ) {
    return Promise.reject("Invalid file headers");
  }

  return Promise.resolve();
};

export const processCustomerData = async (
  data: Array<Array<unknown>>
): Promise<{
  headers: Array<string>;
  customers: Array<SelectedMemberDetails>;
}> => {
  const headers = data[0];

  const customerData = data.slice(1);

  if (customerData.length === 0) {
    return Promise.reject("No data found in the file");
  }

  const customers = customerData.map((customer, index) => {
    if (
      !customer[0] ||
      !customer[1] ||
      !customer[2] ||
      !customer[3] ||
      !["male", "female"].includes(customer[1] as string) ||
      !isPhoneNumber(customer[2] as string) ||
      !isEmail(customer[3] as string)
    ) {
      throw new Error(`Invalid data at row ${index + 2}`);
    }
    return {
      id: index + 1,
      name: customer[0] as string,
      gender: customer[1] as string,
      phone: customer[2] as string,
      email: customer[3] as string,
    };
  });

  return {
    headers: headers as Array<string>,
    customers: customers as Array<SelectedMemberDetails>,
  };
};

export const processCustomerFile = async (file: File) => {
  const fileExtension = file.name.split(".").pop();

  if (fileExtension !== "xlsx") {
    return Promise.reject("Invalid file type");
  }

  if (file.size > 1000000) {
    return Promise.reject("File size should be less than 1MB");
  }

  if (file.size === 0) {
    return Promise.reject("File is empty");
  }

  try {
    const data = await processXlsxFile(file);

    await validateUploadedFile(data as Array<Array<unknown>>);

    const processedData = await processCustomerData(
      data as Array<Array<unknown>>
    );

    return processedData;
  } catch (error) {
    return Promise.reject(error);
  }
};
