import { Download, Delete as DeleteIcon } from "@mui/icons-material";
import { Box, Button, IconButton, Typography } from "@mui/material";
import { MembersList } from "app/modules/Members/BulkImport/MemberList";
import { SelectedMemberDetails } from "app/modules/Members/BulkImport/types";
import { Paths } from "data";
import { useAppSnackbar } from "lib/contexts/SnackbarContext";
import { useNavigate } from "react-router-dom";
import { useBulkUploadCustomersMutation } from "services/customer.slice";

export const Preview = ({
  customerData,
  file,
  onDelete,
  onSuccess,
}: {
  customerData: SelectedMemberDetails[];
  file: File;
  onDelete: () => void;
  onSuccess: () => void;
}) => {
  const snackbar = useAppSnackbar();
  const navigate = useNavigate();

  const [bulkUploadCustomer, { isLoading }] = useBulkUploadCustomersMutation();

  const handleBulkUpload = async () => {
    const payload = customerData.map((customer) => ({
      name: customer.name,
      email: customer.email ? customer.email : undefined,
      phone: customer.phone.toString(),
      gender: customer.gender,
    }));

    const response = await bulkUploadCustomer({
      customers: payload,
    });

    if ("data" in response && response.data.message === "success") {
      snackbar.show("Data imported successfully", { variant: "success" });
      navigate(Paths.MEMBERS.BASE + Paths.MEMBERS.VIEW_ALL);
      onSuccess();
      return;
    }

    if ("error" in response) {
      snackbar.show(response.error as string, { variant: "error" });
      return;
    }
  };

  return (
    <Box width="100%">
      <Box p={3}>
        <Box
          display="flex"
          flexDirection={{ xs: "column-reverse", md: "row" }}
          alignItems={{ xs: "flex-start", md: "center" }}
          justifyContent="space-between"
          rowGap={2}
          marginBottom={2}
        >
          <Typography variant="h6">
            Preview :{" "}
            <Typography component="span" variant="h6" color="text.secondary">
              {file.name}
            </Typography>
          </Typography>
          <Box display="flex" alignItems="center" gap={2}>
            <Button
              onClick={handleBulkUpload}
              disabled={isLoading}
              variant="contained"
              startIcon={<Download />}
            >
              {isLoading ? "Importing.." : "Import data"}
            </Button>
            <IconButton onClick={onDelete} size="large" color="error">
              <DeleteIcon />
            </IconButton>
          </Box>
        </Box>
        <MembersList data={customerData} />
      </Box>
    </Box>
  );
};
