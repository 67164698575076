import { LoadingButton } from "@mui/lab";
import {
  Box,
  Dialog,
  FormControl,
  FormHelperText,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from "@mui/material";
import { selectSelectedBusinessCurrency } from "app/app.slice";
import { useAddPaymentRecordForm } from "app/modules/Billing/RecordPayment/useAddPaymentRecord";
import { Field } from "ui/atoms/Field";
import { PaymentMethodLabels, PaymentMethods } from "lib/constants";
import { useAppSelector } from "lib/store/hooks";
import { Customer } from "types/Customer";
import { Member } from "types/Member";
import { Subscription } from "types/Subscription";
import { ActionableTitleContentCard } from "ui/atoms/ActionableTitleContentCard";

export const PaymentRecordForm = ({
  customerId,
  customerSubscriptionId,
  memberId,
  dueAmount,
  open = false,
  onClose,
  onSuccess,
}: {
  customerId: Customer["id"];
  customerSubscriptionId: Subscription["id"];
  memberId: Member["id"];
  dueAmount: Subscription["dueAmount"];
  open?: boolean;
  onClose: () => void;
  onSuccess: () => void;
}) => {
  const currency = useAppSelector(selectSelectedBusinessCurrency);

  const { formik } = useAddPaymentRecordForm({
    customerId,
    customerSubscriptionId,
    memberId,
    dueAmount,
    onSuccess: () => {
      onSuccess();
      onClose();
    },
  });

  return (
    <Dialog open={open} onClose={onClose}>
      <Box component="form" onSubmit={formik.handleSubmit}>
        <ActionableTitleContentCard>
          <ActionableTitleContentCard.Title>
            Add Payment Record
          </ActionableTitleContentCard.Title>

          <ActionableTitleContentCard.Content>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Field label="Due Amount" value={`${currency} ${dueAmount}`} />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl
                  error={
                    !!formik.touched.paymentMethod &&
                    !!formik.errors.paymentMethod
                  }
                  fullWidth
                >
                  <InputLabel>Payment Method</InputLabel>
                  <Select
                    name="paymentMethod"
                    label="Payment Method"
                    fullWidth
                    value={formik.values.paymentMethod}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.paymentMethod &&
                      Boolean(formik.errors.paymentMethod)
                    }
                  >
                    {Object.values(PaymentMethods).map((key) => (
                      <MenuItem key={key} value={key}>
                        {PaymentMethodLabels[key]}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>
                    {formik.touched.paymentMethod &&
                      formik.errors.paymentMethod}
                  </FormHelperText>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6}>
                <FormControl
                  fullWidth
                  error={
                    formik.touched.amountPaid &&
                    Boolean(formik.errors.amountPaid)
                  }
                >
                  <InputLabel>Amount Paid</InputLabel>
                  <OutlinedInput
                    name="amountPaid"
                    label="Amount Paid"
                    type="number"
                    startAdornment={
                      <InputAdornment position="start">
                        {currency}
                      </InputAdornment>
                    }
                    value={formik.values.amountPaid}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  <FormHelperText>
                    {formik.touched.amountPaid && formik.errors.amountPaid}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="remarks"
                  label="Remarks"
                  variant="outlined"
                  multiline
                  fullWidth
                  rows={2}
                  value={formik.values.remarks}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.remarks && Boolean(formik.errors.remarks)
                  }
                  helperText={formik.touched.remarks && formik.errors.remarks}
                />
              </Grid>
            </Grid>
          </ActionableTitleContentCard.Content>
          <ActionableTitleContentCard.Actions>
            <LoadingButton
              type="submit"
              variant="contained"
              size="large"
              loading={formik.isSubmitting}
            >
              <span>Save</span>
            </LoadingButton>
          </ActionableTitleContentCard.Actions>
        </ActionableTitleContentCard>
      </Box>
    </Dialog>
  );
};
