import { Paths } from "data";
import dayjs, { Dayjs } from "dayjs";
import { useFormik } from "formik";
import { useAppSnackbar } from "lib/contexts/SnackbarContext";
import { useNavigate } from "react-router-dom";
import { useCreateSubscriptionMutation } from "services/subscription.slice";

interface Values {
  price: string;
  startDate: Dayjs | null;
  endDate: Dayjs | null;
  planId: string;
}

const validate = (values: Values) => {
  const errors: {
    price?: string;
    startDate?: string;
    endDate?: string;
    plan?: string;
  } = {};

  if (!values.price) {
    errors.price = "Required";
  }

  if (!values.startDate) {
    errors.startDate = "Required";
  }

  if (!values.endDate) {
    errors.endDate = "Required";
  }

  if (!values.planId) {
    errors.plan = "Required";
  }

  return errors;
};

export const useAddMembershipForm = ({
  customerId,
  onSuccess,
}: {
  customerId: number;
  onSuccess: (subscriptionId: number) => void;
}) => {
  const [createSubscription] = useCreateSubscriptionMutation();

  const snackbar = useAppSnackbar();

  const formik = useFormik<Values>({
    initialValues: {
      planId: "",
      price: "",
      startDate: dayjs(),
      endDate: null,
    },
    validate,
    onSubmit: async (values) => {
      const response = await createSubscription({
        price: parseFloat(values.price),
        startDate: values.startDate ? values.startDate.toISOString() : "",
        endDate: values.endDate ? values.endDate.toISOString() : "",
        planId: parseInt(values.planId),
        customerId,
      });

      if ("data" in response) {
        onSuccess(response.data.id);
      }
      if ("error" in response) {
        snackbar.show(response.error as string, {
          variant: "error",
          preventDuplicate: true,
        });
      }
    },
  });

  return {
    formik,
  };
};
