import { useState } from "react";
import {
  Badge,
  Box,
  CircularProgress,
  Divider,
  IconButton,
  Popover,
  Typography,
} from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";
import {
  useAcceptInviteMutation,
  useGetMyInvitesQuery,
} from "services/team.slice";
import { InvitationWithBusiness } from "types/Invitation";
import { useAppSnackbar } from "lib/contexts/SnackbarContext";
import { LoadingButton } from "@mui/lab";

export const Notifications = () => {
  const {
    data,
    isLoading: isGetMyInvitesLoading,
    isFetching: isGetMyInvitesFetching,
  } = useGetMyInvitesQuery();
  const [acceptInvite, { isLoading }] = useAcceptInviteMutation();

  const snackbar = useAppSnackbar();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleAcceptInvite = async (invitation: InvitationWithBusiness) => {
    try {
      const response = await acceptInvite({
        payload: {
          token: invitation.token,
        },
        setSelectedBusiness: 1,
      }).unwrap();

      if (response.business) {
        snackbar.show("Invitation accepted successfully", {
          variant: "success",
        });

        handleClose();
      }
    } catch {
      snackbar.show("Invitation could not be accepted. Please try again.", {
        variant: "error",
      });
    }
  };

  const open = Boolean(anchorEl);

  const invitations = data?.invitations;

  return (
    <>
      <IconButton
        size="large"
        aria-label="show 17 new notifications"
        color="inherit"
        onClick={handleClick}
      >
        <Badge badgeContent={invitations?.length ?? undefined} color="error">
          <NotificationsIcon />
        </Badge>
      </IconButton>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        {isGetMyInvitesLoading || isGetMyInvitesFetching ? (
          <Box display="flex" justifyContent="center" alignItems="center">
            <CircularProgress size={20} />
          </Box>
        ) : null}
        {!isGetMyInvitesLoading &&
        !isGetMyInvitesFetching &&
        invitations &&
        invitations.length > 0 ? (
          invitations.map((invitation, index) => (
            <>
              <Box
                sx={{ p: 3 }}
                display="flex"
                flexDirection="column"
                alignItems="flex-end"
              >
                <Typography mb={2}>
                  {invitation.business.name} has invited you to join as{" "}
                  {invitation.role}
                </Typography>
                <LoadingButton
                  loading={isLoading}
                  variant="contained"
                  onClick={() => handleAcceptInvite(invitation)}
                >
                  Accept
                </LoadingButton>
              </Box>
              {index < invitations.length - 1 && <Divider />}
            </>
          ))
        ) : (
          <Box p={3}>
            <Typography>No new notifications</Typography>
          </Box>
        )}
      </Popover>
    </>
  );
};
