import { Provider } from "react-redux";
import { CssBaseline } from "@mui/material";
import { BrowserRouter } from "react-router-dom";
import { store } from "lib/store/store";
import { Main } from "app/main/Main";
import { AppSnackbarProvider } from "lib/contexts/SnackbarContext";
import { CustomThemeProvider } from "lib/contexts/CustomThemeProvider";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DialogProvider } from "lib/contexts/DialogProvider";

//TODO: remove helperText from TextField wherever it is not needed
//TODO: Add loading state in buttons
//TODO: Create LocalizationProvider to make the app multilingual
//TODO: Create index.ts files to clean imports
//TODO: Move request and response types from service slices to types folder
//TODO: Create Error Boundary for whole app

export const App = () => {
  return (
    <Provider store={store}>
      <CustomThemeProvider>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <CssBaseline />
          <BrowserRouter>
            <AppSnackbarProvider>
              <DialogProvider>
                <Main />
              </DialogProvider>
            </AppSnackbarProvider>
          </BrowserRouter>
        </LocalizationProvider>
      </CustomThemeProvider>
    </Provider>
  );
};
