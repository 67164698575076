import { Divider, SwipeableDrawer } from "@mui/material";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import { DrawerWidth } from "data";
import { SidebarDrawer } from "ui/components/Sidebar/SidebarDrawer";
import { UserAccount } from "ui/components/Sidebar/UserAccount";
import {
  StyledAccountWrapper,
  StyledDrawerWrapper,
} from "ui/components/Sidebar/styles";

interface Props {
  mobileOpen: boolean;
  handleDrawerTransitionEnd: () => void;
  handleDrawerClose: () => void;
  handleDrawerOpen: () => void;
}

export const Sidebar = ({
  mobileOpen,
  handleDrawerTransitionEnd,
  handleDrawerClose,
  handleDrawerOpen,
}: Props) => {
  return (
    <Box
      component="nav"
      sx={{ width: { sm: DrawerWidth }, flexShrink: { sm: 0 } }}
    >
      <SwipeableDrawer
        variant="temporary"
        open={mobileOpen}
        onTransitionEnd={handleDrawerTransitionEnd}
        onClose={handleDrawerClose}
        onOpen={handleDrawerOpen}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: "flex", sm: "none" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: DrawerWidth,
          },
        }}
      >
        <StyledDrawerWrapper>
          <SidebarDrawer handleDrawerClose={handleDrawerClose} />
        </StyledDrawerWrapper>
        <Divider />
        <StyledAccountWrapper>
          <UserAccount handleDrawerClose={handleDrawerClose} />
        </StyledAccountWrapper>
      </SwipeableDrawer>
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: "none", sm: "flex" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: DrawerWidth,
          },
        }}
        open
      >
        <StyledDrawerWrapper>
          <SidebarDrawer />
        </StyledDrawerWrapper>
        <Divider />
        <StyledAccountWrapper>
          <UserAccount />
        </StyledAccountWrapper>
      </Drawer>
    </Box>
  );
};
