import { Navigate, Route, Routes } from "react-router-dom";
import { Dashboard } from "app/modules/Dashboard/Dashboard";
import { getPathWithoutPrefixSlash } from "app/Routes/helpers";
import { UserProfile } from "app/views/UserProfile/UserProfile";
import { Settings } from "app/views/Settings/Settings";
import { FullPageLayout } from "ui/layouts/FullPageLayout";
import { CreateBusinessForm } from "app/views/CreateBusiness/CreateBusinessForm";
import { MembersView } from "app/modules/Members";
import { AllMembershipPayments } from "app/modules/Billing/MembershipPayments";
import { AddTeamMember } from "app/modules/TeamManagement/AddTeamMember/AddTeamMember";
import { ViewTeam } from "app/modules/TeamManagement/ViewTeam/ViewTeam";
import { BusinessNotification } from "app/views/BusinessNotification/BusinessNotification";
import { PlansView } from "app/modules/Plans";
import { Can } from "ui/components/Can/Can";
import { AppPermission } from "lib/constants";
import { Leads } from "app/modules/Leads/Leads";
import { DashboardLayout } from "../../ui/layouts/DashboardLayout";
import { Paths } from "../../data";
import { Login } from "../login/Login";
import { AuthLayout } from "../../ui/layouts/AuthLayouts";
import { Register } from "../register/Register";

//TODO: lazy load routes

export const Router = () => {
  return (
    <Routes>
      {/* Auth Roues */}
      <Route path={Paths.AUTH.BASE} element={<AuthLayout />}>
        <Route
          path={getPathWithoutPrefixSlash(Paths.AUTH.LOGIN)}
          element={<Login />}
        />
        <Route
          path={getPathWithoutPrefixSlash(Paths.AUTH.REGISTER)}
          element={<Register />}
        />
      </Route>

      {/* Create Business Routes */}
      <Route path={Paths.BUSINESS.BASE} element={<FullPageLayout />}>
        <Route index element={<CreateBusinessForm />} />
        <Route
          path={getPathWithoutPrefixSlash(Paths.BUSINESS.NOTIFICATION)}
          element={<BusinessNotification />}
        />
      </Route>

      {/* Dashboard Routes */}
      <Route path={Paths.DASHBOARD.BASE} element={<DashboardLayout />}>
        <Route index element={<Dashboard />} />

        {/* Members Module Routes */}
        <Route
          path={getPathWithoutPrefixSlash(
            Paths.MEMBERS.BASE + Paths.MEMBERS.VIEW_ALL
          )}
          element={<MembersView />}
        />

        {/* Plans Module Routes */}
        <Route
          path={getPathWithoutPrefixSlash(
            Paths.PLANS.BASE + Paths.PLANS.VIEW_ALL
          )}
          element={<PlansView />}
        />

        {/* Billing Module Routes */}
        <Route
          path={getPathWithoutPrefixSlash(
            Paths.BILLING.BASE + Paths.BILLING.MEMBERSHIP_PAYMENTS
          )}
          element={<AllMembershipPayments />}
        />

        {/* Manage Team Module Routes */}
        <Route
          path={getPathWithoutPrefixSlash(
            Paths.MANAGE_TEAM.BASE + Paths.MANAGE_TEAM.ADD
          )}
          element={
            <Can
              I={[AppPermission.ManageTeamMembers]}
              strict={false}
              variant="PAGE"
            >
              <AddTeamMember />
            </Can>
          }
        />

        {/* Leads Module Routes */}
        <Route
          path={getPathWithoutPrefixSlash(
            Paths.LEADS.BASE + Paths.LEADS.VIEW_ALL
          )}
          element={<Leads />}
        />

        <Route
          path={getPathWithoutPrefixSlash(
            Paths.MANAGE_TEAM.BASE + Paths.MANAGE_TEAM.VIEW_ALL
          )}
          element={<ViewTeam />}
        />

        {/* Other routes */}
        <Route
          path={getPathWithoutPrefixSlash(Paths.USER_PROFILE)}
          element={<UserProfile />}
        />
        <Route
          path={getPathWithoutPrefixSlash(Paths.SETTINGS)}
          element={<Settings />}
        />
      </Route>

      <Route path="*" element={<Navigate to={Paths.DASHBOARD.BASE} />} />
    </Routes>
  );
};
