import { createContext } from "react";
import {
  SnackbarProvider as NotistackSnackbarProvider,
  useSnackbar,
} from "notistack";
import { MuiSuccessSnackbar } from "ui/components/Snackbars/MuiSuccessSnackbar";

export type SnackbarContextType = null;

export const SnackbarContext = createContext<SnackbarContextType>(null);

export const AppSnackbarProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  return (
    <SnackbarContext.Provider value={null}>
      <NotistackSnackbarProvider
        maxSnack={3}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        Components={{
          muiSuccess: MuiSuccessSnackbar,
        }}
      >
        {children}
      </NotistackSnackbarProvider>
    </SnackbarContext.Provider>
  );
};

export const useAppSnackbar = () => {
  const { closeSnackbar, enqueueSnackbar } = useSnackbar();

  return {
    show: enqueueSnackbar,
    hide: closeSnackbar,
  };
};
