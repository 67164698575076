import {
  resetAppData,
  selectStatus,
  setAppData,
  setAppStatus,
} from "app/app.slice";
import { useDialog } from "lib/contexts/DialogProvider";
import { useGetAppData } from "lib/hooks/useGetAppData";
import { addAuthCustomEventListeners } from "lib/modules/addCustomEventListeners";
import { transformAppData } from "lib/modules/transformAppData";
import { resetState } from "lib/store/actions";
import { useAppDispatch, useAppSelector } from "lib/store/hooks";
import { useCallback, useEffect } from "react";
import { useLogoutMutation } from "services/auth.slice";
import { TransformedAppData } from "types/TransformedAppData";
import { AppLoadingStatusType } from "types/utility-types";
import { SessionExpiredDialog } from "ui/components/SessionExpiredDialog.tsx/SessionExpiredDialog";

interface useInitiateAppProps {
  initiateApp: ({
    type,
    onSuccess,
  }: {
    type: "SIGN_IN" | "RELOAD";
    onSuccess?: () => void;
  }) => void;
  appLoadingStatus: AppLoadingStatusType;
}

export const useInitiateApp = (): useInitiateAppProps => {
  const dispatch = useAppDispatch();
  const appLoadingStatus = useAppSelector(selectStatus);
  const { getDataAtReload, getDataAtSignIn } = useGetAppData();
  const [logout] = useLogoutMutation();
  const { openDialog } = useDialog();

  const initiateApp = useCallback(
    async ({
      type,
      onSuccess,
    }: {
      type: "SIGN_IN" | "RELOAD";
      onSuccess?: () => void;
    }) => {
      try {
        let transformedData: TransformedAppData;

        if (type === "SIGN_IN") {
          const appData = await getDataAtSignIn();
          transformedData = transformAppData(appData);
        } else {
          const appData = await getDataAtReload();
          transformedData = transformAppData(appData);
        }

        dispatch(setAppData(transformedData));
        dispatch(setAppStatus("SUCCESS"));

        onSuccess && onSuccess();
      } catch (error) {
        dispatch(setAppStatus("ERROR"));
        console.error(error);
      }
    },
    [dispatch, getDataAtReload, getDataAtSignIn]
  );

  const logoutApp = useCallback(
    async ({ onSuccess }: { onSuccess?: () => void }) => {
      dispatch(setAppStatus("LOADING"));

      try {
        const response = await logout(null);

        if ("error" in response) {
          alert(response.error);
          return;
        }

        if ("data" in response) {
          dispatch(resetState());
          dispatch(resetAppData());
          dispatch(setAppStatus("SUCCESS"));
        }

        onSuccess && onSuccess();
      } catch {
        dispatch(setAppStatus("ERROR"));
      }
    },
    [dispatch, logout]
  );

  const openSessionExpiredDialog = useCallback(() => {
    openDialog({
      dialog: <SessionExpiredDialog />,
    });
  }, [openDialog]);

  useEffect(() => {
    initiateApp({ type: "RELOAD" });
    addAuthCustomEventListeners({
      initiateApp,
      logoutApp,
      openSessionExpiredDialog,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    initiateApp,
    appLoadingStatus,
  };
};
