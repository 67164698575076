import { LoadingButton } from "@mui/lab";
import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useAddTeamMember } from "app/modules/TeamManagement/AddTeamMember/useAddTeamMember";
import { Roles } from "app/modules/TeamManagement/constants";
import { AppPermission } from "lib/constants";
import { useGetRolesAndPermissionsQuery } from "services/team.slice";
import { ActionableTitleContentCard } from "ui/atoms/ActionableTitleContentCard";
import { Can } from "ui/components/Can/Can";
import { PageSpinner } from "ui/components/Loader/PageSpinner";
import { PageError } from "ui/components/PageError/PageError";

export const AddTeamMember = () => {
  const { data, isLoading, isFetching } = useGetRolesAndPermissionsQuery();
  const { formik } = useAddTeamMember();

  if (isLoading || isFetching) {
    return <PageSpinner />;
  }

  if (!data) {
    return <PageError />;
  }

  return (
    <Box
      padding={{ xs: 2, sm: 3 }}
      component="form"
      onSubmit={formik.handleSubmit}
    >
      <ActionableTitleContentCard>
        <ActionableTitleContentCard.Title>
          User Details
        </ActionableTitleContentCard.Title>

        <ActionableTitleContentCard.Content>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <TextField
                name="email"
                label="Email"
                variant="outlined"
                fullWidth
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <FormControl
                error={!!formik.touched.role && !!formik.errors.role}
                fullWidth
              >
                <InputLabel>Roles</InputLabel>
                <Select
                  name="role"
                  label="Role"
                  fullWidth
                  value={formik.values.role}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.role && Boolean(formik.errors.role)}
                >
                  {data.allRoles
                    .filter(
                      (role) => role.toLowerCase() !== Roles.Owner.toLowerCase()
                    )
                    .map((role, index) => (
                      <MenuItem key={index} value={role}>
                        {role}
                      </MenuItem>
                    ))}
                </Select>
                <FormHelperText>
                  {formik.touched.role && formik.errors.role}
                </FormHelperText>
              </FormControl>
            </Grid>
          </Grid>

          {formik.values.email && formik.values.role ? (
            <Box marginTop={3}>
              <Typography variant="body1" gutterBottom>
                Following permissions will be granted to this user
              </Typography>
              <Typography variant="body2" color="textSecondary">
                <ul>
                  {data.rolesWithPermissions[formik.values.role].map(
                    (permission, index) => (
                      <li key={index}>{permission}</li>
                    )
                  )}
                </ul>
              </Typography>
            </Box>
          ) : null}
        </ActionableTitleContentCard.Content>
        <ActionableTitleContentCard.Actions>
          <Can
            I={[AppPermission.ManageTeamMembers]}
            strict={false}
            variant="OVERLAY"
          >
            <LoadingButton
              type="submit"
              variant="contained"
              size="large"
              loading={formik.isSubmitting}
            >
              <span>Save</span>
            </LoadingButton>
          </Can>
        </ActionableTitleContentCard.Actions>
      </ActionableTitleContentCard>
    </Box>
  );
};
