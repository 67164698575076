import { Business } from "types/Business";
import { RawAppData } from "types/RawAppData";
import { TransformedAppData } from "types/TransformedAppData";

export const transformAppData = (data: RawAppData): TransformedAppData => {
  const selectedBusiness = data.businesses.find(
    (business) => business.id === data.selectedBusinessId
  ) as Business; //TODO: check if we can avoid this typecast

  if (data.businesses.length > 0 && !selectedBusiness) {
    throw new Error("Selected business not found");
  }

  const profile = data.userProfile;

  return {
    business: {
      selectedBusiness,
      businesses: data.businesses,
    },
    user: {
      ...data.user,
      profile,
      isLoggedIn: true,
    },
  };
};
