import { Box } from "@mui/material";
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridRowsProp,
} from "@mui/x-data-grid";
import {
  selectSelectedBusiness,
  selectSelectedBusinessCurrency,
} from "app/app.slice";
import { useAppSelector } from "lib/store/hooks";
import { useGetAllPlansQuery } from "services/plan.slice";
import { PlanList } from "services/types/plan.types";
import { Plan } from "types/Plan";
import { PageSpinner } from "ui/components/Loader/PageSpinner";

//TODO: update columns and column width

const getTableRows = (currency: string, data?: PlanList): GridRowsProp => {
  if (!data) return [];

  return data.map((plan: Plan) => ({
    id: plan.id,
    name: plan.name,
    description: plan.description,
    minPrice: { minPrice: plan.minPrice, currency },
    maxPrice: { maxPrice: plan.maxPrice, currency },
    duration: {
      intervalCount: plan.intervalCount,
      intervalUnit: plan.intervalUnit,
    },
    active: plan.active,
    isRecurring: plan.isRecurring,
    startDate: plan.startDate ? new Date(plan.startDate) : null,
    endDate: plan.endDate ? new Date(plan.endDate) : null,
    isRegistrationFeeRequired: plan.isRegistrationFeeRequired,
  }));
};

const columns: GridColDef[] = [
  { field: "id", headerName: "ID", width: 50 },
  { field: "name", headerName: "Name", width: 200 },
  { field: "description", headerName: "Description", width: 150 },
  {
    field: "minPrice",
    headerName: "Minimum Price",
    width: 150,
    renderCell: (params: GridRenderCellParams) => {
      const { minPrice, currency } = params.value as {
        minPrice: number;
        currency: string;
      };

      return (
        <Box display="flex" alignItems="center">
          {/* //TODO: add currency symbol */}
          {minPrice} {currency}
        </Box>
      );
    },
  },
  {
    field: "maxPrice",
    headerName: "Maximum Price",
    width: 150,
    renderCell: (params: GridRenderCellParams) => {
      const { maxPrice, currency } = params.value as {
        maxPrice: number;
        currency: string;
      };

      return (
        <Box display="flex" alignItems="center">
          {/* //TODO: add currency symbol */}
          {maxPrice} {currency}
        </Box>
      );
    },
  },
  {
    field: "duration",
    headerName: "Duration",
    width: 100,
    renderCell: (params: GridRenderCellParams) => {
      const { intervalCount, intervalUnit } = params.value as {
        intervalCount: number;
        intervalUnit: string;
      };

      return (
        <Box display="flex" alignItems="center">
          {/* //TODO: handle pluralization */}
          {intervalCount} {intervalUnit}
        </Box>
      );
    },
  },
  { field: "active", headerName: "Active", width: 100, type: "boolean" },
  {
    field: "isRecurring",
    headerName: "Recurring",
    width: 100,
    type: "boolean",
  },
  { field: "startDate", headerName: "Start Date", width: 125, type: "date" },
  { field: "endDate", headerName: "End Date", width: 125, type: "date" },
  {
    field: "isRegistrationFeeRequired",
    headerName: "Registration Fees Required",
    width: 100,
    type: "boolean",
  },
];

const CustomNoRowsOverlay = () => {
  return (
    <Box
      width="100%"
      height="100%"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      No Rows Found
    </Box>
  );
};

export const AllPlans = () => {
  const { data, isLoading } = useGetAllPlansQuery(undefined);

  const currency = useAppSelector(selectSelectedBusinessCurrency);

  const rows = getTableRows(currency, data);

  if (isLoading) {
    return <PageSpinner />;
  }

  return (
    <Box padding={{ xs: 2, sm: 3 }} width="100%" height="600px">
      <DataGrid
        rows={rows}
        columns={columns}
        slots={{ noRowsOverlay: CustomNoRowsOverlay }}
        checkboxSelection
      />
    </Box>
  );
};
