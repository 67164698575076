import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { TeamMembers } from "app/modules/TeamManagement/ViewTeam/TeamMembers";
import { Invitations } from "app/modules/TeamManagement/ViewTeam/Invitations";
import { Can } from "ui/components/Can/Can";
import { AppPermission } from "lib/constants";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export const ViewTeam = () => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%", pt: 1, bgcolor: "background.paper" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab sx={{ px: 6 }} label="Invitations" {...a11yProps(0)} />
          <Tab sx={{ px: 6 }} label="Team Members" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <Can
          I={[AppPermission.ManageTeamMembers]}
          strict={false}
          variant="CONTAINER"
        >
          <Invitations />
        </Can>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <Can
          I={[AppPermission.ViewTeamMembers]}
          strict={false}
          variant="CONTAINER"
        >
          <TeamMembers />
        </Can>
      </CustomTabPanel>
    </Box>
  );
};
