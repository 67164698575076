import { useNavigate } from "react-router-dom";
import type { DialogProps } from "@mui/material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { resetAppData } from "app/app.slice";
import { Paths } from "data";
import { useDialog } from "lib/contexts/DialogProvider";
import { useAppDispatch } from "lib/store/hooks";

export const SessionExpiredDialog = (): JSX.Element => {
  const { isOpen, closeDialog } = useDialog();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleLoginNow = () => {
    dispatch(resetAppData());
    closeDialog();
    navigate(Paths.AUTH.BASE + Paths.AUTH.LOGIN);
  };

  const handleClose: DialogProps["onClose"] = (event, reason) => {
    if (reason === "backdropClick") return;
    closeDialog();
  };

  return (
    <Dialog
      disableEscapeKeyDown
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="session-expired"
      aria-describedby="session-expired-dialog"
    >
      <DialogTitle>Session Expired</DialogTitle>
      <DialogContent>
        <DialogContentText>
          You are required to login again since you have been inactive for the
          past few hours. You will be redirected to our login page
          automatically.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleLoginNow} autoFocus>
          Login In Now
        </Button>
      </DialogActions>
    </Dialog>
  );
};
