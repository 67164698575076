import { useFormik } from "formik";
import { PaymentMethods } from "lib/constants";
import { useAppSnackbar } from "lib/contexts/SnackbarContext";
import { PaymentMethod } from "lib/types";
import { useCreateSubscriptionPaymentMutation } from "services/subscriptionPayments.slice";
import { Customer } from "types/Customer";
import { Member } from "types/Member";
import { Subscription } from "types/Subscription";

const validate = (values: {
  amountPaid: string;
  remarks?: string;
  paymentMethod: PaymentMethod;
}) => {
  const errors: {
    amountPaid?: string;
    remarks?: string;
    paymentMethod?: string;
  } = {};

  if (!values.amountPaid) {
    errors.amountPaid = "Required";
  }

  if (!values.paymentMethod) {
    errors.paymentMethod = "Required";
  }

  return errors;
};

export const useAddPaymentRecordForm = ({
  customerId,
  customerSubscriptionId,
  memberId,
  dueAmount,
  onSuccess,
}: {
  customerId: Customer["id"];
  customerSubscriptionId: Subscription["id"];
  memberId: Member["id"];
  dueAmount: Subscription["dueAmount"];
  onSuccess: () => void;
}) => {
  const [createSubscriptionPayment] = useCreateSubscriptionPaymentMutation();

  const snackbar = useAppSnackbar();

  const formik = useFormik({
    initialValues: {
      paymentMethod: PaymentMethods.CASH,
      amountPaid: "",
      remarks: "",
    },
    validate,
    onSubmit: async (values) => {
      const amountPaid = Number(values.amountPaid);

      if (amountPaid > dueAmount) {
        snackbar.show("Amount paid cannot be more than due amount", {
          variant: "error",
          preventDuplicate: true,
        });
        return;
      }

      const response = await createSubscriptionPayment({
        amount: Number(values.amountPaid),
        paymentMethod: values.paymentMethod,
        customerId,
        customerSubscriptionId,
        memberId,
        remarks: values.remarks,
      });
      if ("data" in response) {
        snackbar.show("Payment created successfully", { variant: "success" });
        onSuccess();
        //TODO: reset form or navigate to another page
      }
      if ("error" in response) {
        snackbar.show(response.error as string, {
          variant: "error",
          preventDuplicate: true,
        });
      }
    },
  });

  return {
    formik,
  };
};
