import { selectUser } from "app/app.slice";
import { useAppSelector } from "lib/store/hooks";
import { useGetRolesAndPermissionsQuery } from "services/team.slice";

export const useMyPermissions = () => {
  const user = useAppSelector(selectUser);

  const { data } = useGetRolesAndPermissionsQuery();

  const myPermissions =
    Object.entries(data?.rolesWithPermissions ?? {}).find(
      ([role]) => role === user?.businessRole
    )?.[1] || [];

  return myPermissions;
};
