import { selectUser } from "app/app.slice";
import { Dayjs } from "dayjs";
import { useFormik } from "formik";
import { useAppSnackbar } from "lib/contexts/SnackbarContext";
import { BooleanValuesForSelectOption } from "lib/types";
import { isPhoneNumber } from "lib/utils/validators/isPhoneNumber";
import { useUpdateCustomerMutation } from "services/customer.slice";
import { Customer } from "types/Customer";
import { MemberDetails } from "types/Member";

const validate = (values: {
  name: MemberDetails["name"];
  email: MemberDetails["email"];
  phone: MemberDetails["phone"];
  gender: MemberDetails["gender"];
  address: MemberDetails["address"];
  dob: Dayjs | null;
  active: BooleanValuesForSelectOption | "";
  nameOnInvoice: Customer["nameOnInvoice"];
}) => {
  const errors: {
    name?: string;
    email?: string;
    phone?: string;
    gender?: string;
    dob?: string;
    address?: string;
  } = {};

  if (!values.phone) {
    errors.phone = "Phone Number is required";
  } else if (!isPhoneNumber(values.phone)) {
    errors.phone = "Invalid Mobile Number";
  }

  if (!values.name) {
    errors.name = "Name is required";
  }

  if (!values.email) {
    errors.email = "Email is required";
  }

  if (!values.gender) {
    errors.gender = "Gender is required";
  }

  if (!values.dob) {
    errors.dob = "Date of Birth is required";
  }

  if (!values.address) {
    errors.address = "Address is required";
  }

  return errors;
};

//TODO: fix all optional fields
export const useUpdateCustomer = (
  initialValues: {
    name?: MemberDetails["name"];
    email?: MemberDetails["email"];
    phone?: MemberDetails["phone"];
    gender?: MemberDetails["gender"];
    address?: MemberDetails["address"];
    dob?: Dayjs | null;
    active?: BooleanValuesForSelectOption;
    nameOnInvoice?: Customer["nameOnInvoice"];
  },
  memberId?: number,
  customerId?: number
) => {
  const [updateCustomer] = useUpdateCustomerMutation();

  const snackbar = useAppSnackbar();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: initialValues.email || "",
      name: initialValues.name || "",
      phone: initialValues.phone || "",
      gender: initialValues.gender || "",
      dob: initialValues.dob || null,
      address: initialValues.address || "",
      active: initialValues.active || "",
      nameOnInvoice: initialValues.nameOnInvoice || "",
    },
    validate,
    onSubmit: async (values) => {
      if (!memberId || !customerId)
        return snackbar.show("Member ID or Customer ID is missing", {
          variant: "error",
        });

      const response = await updateCustomer({
        memberId,
        customerId,
        email: values.email,
        name: values.name,
        phone: values.phone,
        address: values.address,
        gender: values.gender,
        dob: values.dob ? values.dob?.toISOString() : "",
        active: values.active ? Boolean(values.active) : undefined,
        nameOnInvoice: values.nameOnInvoice,
      });

      if ("data" in response) {
        snackbar.show("Member details updated successfully", {
          variant: "success",
        });
        // navigate(Paths.MEMBERS.BASE + Paths.MEMBERS.VIEW_ALL);
      }

      if ("error" in response) {
        snackbar.show(response.error as string, {
          variant: "error",
          preventDuplicate: true,
        });
      }
    },
  });

  return {
    formik,
  };
};
