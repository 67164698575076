import { Avatar, Box } from "@mui/material";
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridRowsProp,
} from "@mui/x-data-grid";
import { useGetAllCustomersQuery } from "services/customer.slice";
import {
  CustomerWithMemberAndMemberDetails,
  CustomerWithMemberAndMemberDetailsList,
} from "services/types/member.types";
import { PageSpinner } from "ui/components/Loader/PageSpinner";

//TODO: update columns and column width

const getTableRows = (
  data?: CustomerWithMemberAndMemberDetailsList
): GridRowsProp => {
  if (!data) return [];

  return data.map((customer: CustomerWithMemberAndMemberDetails) => ({
    id: customer.id,
    name: customer.member.memberDetails?.name,
    gender: customer.member.memberDetails?.gender,
    contact: customer.member.memberDetails?.phone,
    email: customer.member.memberDetails?.email,
    photo: "https://i.pravatar.cc/500",
  }));
};

//TODO: add created at, joined at, updated at, and actions
const columns: GridColDef[] = [
  { field: "id", headerName: "ID", width: 50 },
  {
    field: "photo",
    headerName: "Photo",
    width: 150,
    renderCell: (params: GridRenderCellParams<any, string>) => (
      <Box
        height="100%"
        display="flex"
        justifyContent="flex-start"
        alignItems="center"
      >
        {params.value ? (
          <Avatar
            sx={{ width: 30, height: 30 }}
            src="https://i.pravatar.cc/300"
          >
            {params.value}
          </Avatar>
        ) : (
          "No Photo"
        )}
      </Box>
    ),
  },
  { field: "name", headerName: "Name", width: 250 },
  { field: "gender", headerName: "Gender", width: 150 },
  { field: "contact", headerName: "Contact", width: 200 },
  { field: "email", headerName: "Email", width: 300 },
];

const CustomNoRowsOverlay = () => {
  return (
    <Box
      width="100%"
      height="100%"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      No Rows Found
    </Box>
  );
};

export const AllMembers = () => {
  const { data, isLoading } = useGetAllCustomersQuery(undefined);

  const rows = getTableRows(data);

  if (isLoading) {
    return <PageSpinner />;
  }

  return (
    <Box width="100%" height="600px" paddingBottom={4}>
      <DataGrid
        rows={rows}
        columns={columns}
        slots={{ noRowsOverlay: CustomNoRowsOverlay }}
        checkboxSelection
      />
    </Box>
  );
};
