import dayjs from "dayjs";
import { Delete } from "@mui/icons-material";
import {
  Box,
  Chip,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { ConfirmDeleteInvite } from "app/modules/TeamManagement/ViewTeam/ConfirmDeleteInvite";
import {
  StyledTableCell,
  StyledTableRow,
} from "app/modules/TeamManagement/ViewTeam/styles";
import { useAppSnackbar } from "lib/contexts/SnackbarContext";
import { useRef, useState } from "react";
import {
  useDeleteInviteMutation,
  useGetAllInvitesQuery,
} from "services/team.slice";
import { Invitation } from "types/Invitation";
import { Role } from "types/Team";
import { PageSpinner } from "ui/components/Loader/PageSpinner";
import { PageError } from "ui/components/PageError/PageError";

function createData({
  email,
  role,
  expireAt,
  onDeleteInvite,
}: {
  email: string;
  role: Role;
  expireAt: Date;
  onDeleteInvite: () => void;
}) {
  return {
    email,
    role,
    status: <Chip size="small" label="Invited" color="info" />,
    expireAt: dayjs(expireAt).format("DD MMM YYYY hh:mm A"),
    actions: (
      <IconButton color="error" size="small" onClick={onDeleteInvite}>
        <Delete />
      </IconButton>
    ),
  };
}

export const Invitations = () => {
  const { data, isLoading, isFetching } = useGetAllInvitesQuery();

  const snackbar = useAppSnackbar();

  const [deleteInvite, { isLoading: isDeleting }] = useDeleteInviteMutation();

  const currentDeleteInviteId = useRef<Invitation["id"] | null>(null);

  const [isDeleteInviteDialogOpen, setIsDeleteInviteDialogOpen] =
    useState(false);

  const handleDeleteInviteDialogOpen = (id: Invitation["id"]) => {
    currentDeleteInviteId.current = id;
    setIsDeleteInviteDialogOpen(true);
  };

  const handleDeleteInviteDialogClose = () => {
    setIsDeleteInviteDialogOpen(false);
  };

  const handleConfirmDeleteInvite = async () => {
    if (!currentDeleteInviteId.current) {
      return;
    }

    const response = await deleteInvite({
      id: currentDeleteInviteId.current,
    }).unwrap();

    currentDeleteInviteId.current = null;

    if (!response.message) {
      snackbar.show("Failed to delete invite", { variant: "error" });
      return;
    }

    snackbar.show("Invite deleted successfully", { variant: "success" });
    handleDeleteInviteDialogClose();
  };

  if (isLoading || isFetching) {
    return <PageSpinner />;
  }

  if (!data || !data.invites) {
    return <PageError />;
  }

  const invites = data.invites;

  const rows = invites.map((invite) => {
    return createData({
      email: invite.email,
      role: invite.role || "",
      expireAt: new Date(invite.expireAt),
      onDeleteInvite: () => handleDeleteInviteDialogOpen(invite.id),
    });
  });

  if (rows.length === 0) {
    return (
      <Box
        width="100%"
        height="100%"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        No Invites Found
      </Box>
    );
  }

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="all memberships">
        <TableHead>
          <TableRow>
            <StyledTableCell>Email</StyledTableCell>
            <StyledTableCell align="center">Role</StyledTableCell>
            <StyledTableCell align="center">Status</StyledTableCell>
            <StyledTableCell align="center">Expire At</StyledTableCell>
            <StyledTableCell align="center">Actions</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => (
            <StyledTableRow key={index}>
              <StyledTableCell sx={{ width: 300 }}>{row.email}</StyledTableCell>
              <StyledTableCell
                align="center"
                sx={{ width: 200, fontWeight: 700 }}
              >
                {row.role}
              </StyledTableCell>
              <StyledTableCell align="center" sx={{ width: 200 }}>
                {row.status}
              </StyledTableCell>
              <StyledTableCell align="center" sx={{ width: 200 }}>
                {row.expireAt}
              </StyledTableCell>
              <StyledTableCell align="center" sx={{ width: 50 }}>
                {row.actions}
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
      <ConfirmDeleteInvite
        isLoading={isDeleting}
        isOpen={isDeleteInviteDialogOpen}
        onClose={handleDeleteInviteDialogClose}
        onDeleteInvite={handleConfirmDeleteInvite}
      />
    </TableContainer>
  );
};
