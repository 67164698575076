import { Typography, Box, Button, TextField } from "@mui/material";
import { NavLink } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import { LoginWrapper } from "app/login/styles";
import { useLoginForm } from "app/login/useLoginForm";
import { PasswordField } from "ui/atoms/PasswordField";
import { Paths } from "data";

export const Login = () => {
  const { formik } = useLoginForm();

  return (
    <LoginWrapper>
      <Box display="flex" marginBottom={5}>
        <Typography variant="h6" component="h1" fontWeight={600}>
          Login
        </Typography>
        <Box sx={{ flexGrow: 1 }} />
        <NavLink to={Paths.AUTH.BASE + Paths.AUTH.REGISTER}>
          <Button>Don&#39;t have an account?</Button>
        </NavLink>
      </Box>
      <Box
        component="form"
        onSubmit={formik.handleSubmit}
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        gap={3}
      >
        <TextField
          name="phone"
          label="Mobile Number"
          variant="outlined"
          value={formik.values.phone}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.phone && Boolean(formik.errors.phone)}
          helperText={formik.touched.phone && formik.errors.phone}
          fullWidth
        />
        <PasswordField
          name="password"
          variant="outlined"
          label="Password"
          value={formik.values.password}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.password && Boolean(formik.errors.password)}
          helperText={formik.touched.password && formik.errors.password}
          fullWidth
        />
        <LoadingButton
          type="submit"
          variant="contained"
          size="large"
          loading={formik.isSubmitting}
          fullWidth
        >
          <span>Login</span>
        </LoadingButton>
      </Box>
    </LoginWrapper>
  );
};
