import { Box, Tooltip, Typography } from "@mui/material";
import { AppPermission } from "lib/constants";
import { useIsAllowed } from "lib/hooks/useIsAllowed";

export const Can = ({
  I,
  children,
  strict = true,
  variant = "PAGE",
  type = "ALL",
}: {
  children: React.ReactNode;
  I: AppPermission[];
  strict?: boolean;
  variant?: "PAGE" | "TEXT" | "OVERLAY" | "CONTAINER";
  type?: "ANY" | "ALL";
}) => {
  const isAllowed = useIsAllowed({ actions: I, type });

  if (isAllowed) {
    return <>{children}</>;
  }

  if (strict) {
    return null;
  }

  if (variant === "PAGE") {
    return (
      <Box
        width="100%"
        height="100%"
        display="flex"
        justifyContent="center"
        alignItems="center"
        bgcolor="rgba(0, 0, 0, 0.5)"
      >
        <Typography color="textSecondary">
          You are not allowed to view this page
        </Typography>
      </Box>
    );
  }

  if (variant === "CONTAINER") {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        px={3}
        py={2}
      >
        <Typography color="textSecondary">
          You are not allowed to view this content
        </Typography>
      </Box>
    );
  }

  if (variant === "TEXT") {
    return (
      <Typography color="textSecondary">
        You are not allowed to view this content
      </Typography>
    );
  }

  return (
    <Tooltip title="Not Allowed">
      <Box component="span" sx={{ cursor: "not-allowed" }}>
        <Box sx={{ pointerEvents: "none" }}>{children}</Box>
      </Box>
    </Tooltip>
  );
};
