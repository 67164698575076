import { Backdrop, CircularProgress } from "@mui/material";

const CircularLoading = () => (
  <Backdrop
    sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
    open={true}
  >
    <CircularProgress color="inherit" size={50} />
  </Backdrop>
);

export const FullPageSpinner = () => {
  return <CircularLoading />;
};
