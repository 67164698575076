import {
  Box,
  Button,
  Grid,
  TextField,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useUpdateCustomer } from "app/modules/Members/ViewMember/useUpdateCustomer";
import dayjs, { Dayjs } from "dayjs";
import { BooleanValuesForSelectOptions } from "lib/constants";
import React from "react";
import { CustomerWithMemberAndMemberDetails } from "services/types/member.types";
import { ActionableTitleContentCard } from "ui/atoms/ActionableTitleContentCard";

export const MemberDetailsForm = ({
  selectedCustomer,
}: {
  selectedCustomer: CustomerWithMemberAndMemberDetails | null;
}) => {
  const { formik } = useUpdateCustomer(
    {
      name: selectedCustomer?.member.memberDetails.name,
      email: selectedCustomer?.member.memberDetails.email,
      phone: selectedCustomer?.member.memberDetails.phone,
      address: selectedCustomer?.member.memberDetails.address,
      gender: selectedCustomer?.member.memberDetails.gender,
      nameOnInvoice: selectedCustomer?.nameOnInvoice,
      dob: selectedCustomer?.member.memberDetails.dob
        ? dayjs(new Date(selectedCustomer?.member.memberDetails.dob))
        : null,
      active:
        selectedCustomer?.member.active == null
          ? undefined
          : selectedCustomer?.member.active
          ? BooleanValuesForSelectOptions.TRUE
          : BooleanValuesForSelectOptions.FALSE,
    },
    selectedCustomer?.member.id,
    selectedCustomer?.id
  );

  const handleDateOfBirthChange = (date: Dayjs | null) => {
    formik.setFieldValue("dob", date);
  };

  if (!selectedCustomer) return null;

  return (
    <React.Fragment>
      <Box component="form" onSubmit={formik.handleSubmit}>
        <ActionableTitleContentCard>
          <ActionableTitleContentCard.Title>
            Details
          </ActionableTitleContentCard.Title>
          <ActionableTitleContentCard.Content>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <TextField
                  name="name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                  label="Name"
                  variant="outlined"
                  type="text"
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  name="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                  label="Email"
                  variant="outlined"
                  type="email"
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  name="phone"
                  value={formik.values.phone}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.phone && Boolean(formik.errors.phone)}
                  helperText={formik.touched.phone && formik.errors.phone}
                  label="Phone Number"
                  variant="outlined"
                  type="number"
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <FormControl
                  error={!!formik.touched.gender && !!formik.errors.gender}
                  fullWidth
                >
                  <InputLabel>Gender</InputLabel>
                  <Select
                    name="gender"
                    label="Gender"
                    fullWidth
                    value={formik.values.gender}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.gender && Boolean(formik.errors.gender)
                    }
                  >
                    <MenuItem value="male">Male</MenuItem>
                    <MenuItem value="female">Female</MenuItem>
                    <MenuItem value="other">Other</MenuItem>
                  </Select>
                  <FormHelperText>
                    {formik.touched.gender && formik.errors.gender}
                  </FormHelperText>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  name="address"
                  label="Address"
                  variant="outlined"
                  type="text"
                  multiline
                  fullWidth
                  value={formik.values.address}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.address && Boolean(formik.errors.address)
                  }
                  helperText={formik.touched.address && formik.errors.address}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <DatePicker
                  name="dob"
                  label="Date Of Birth"
                  sx={{ width: "100%" }}
                  value={formik.values.dob}
                  onChange={handleDateOfBirthChange}
                  slotProps={{
                    textField: {
                      error: !!formik.errors.dob,
                      helperText: formik.errors.dob,
                    },
                  }}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <FormControl
                  error={!!formik.touched.active && !!formik.errors.active}
                  fullWidth
                >
                  <InputLabel>Active</InputLabel>
                  <Select
                    name="active"
                    label="Active"
                    fullWidth
                    value={formik.values.active}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.active && Boolean(formik.errors.active)
                    }
                  >
                    <MenuItem value={BooleanValuesForSelectOptions.TRUE}>
                      Yes
                    </MenuItem>
                    <MenuItem value={BooleanValuesForSelectOptions.FALSE}>
                      No
                    </MenuItem>
                  </Select>
                  <FormHelperText>
                    {formik.touched.active && formik.errors.active}
                  </FormHelperText>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  name="nameOfInvoice"
                  value={formik.values.nameOnInvoice}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.nameOnInvoice &&
                    Boolean(formik.errors.nameOnInvoice)
                  }
                  helperText={
                    formik.touched.nameOnInvoice && formik.errors.nameOnInvoice
                  }
                  label="Name On Invoice"
                  variant="outlined"
                  type="text"
                  fullWidth
                />
              </Grid>
            </Grid>
          </ActionableTitleContentCard.Content>
          <ActionableTitleContentCard.Actions>
            <>
              <Button
                type="submit"
                variant="contained"
                size="large"
                color="primary"
              >
                Update
              </Button>
            </>
          </ActionableTitleContentCard.Actions>
        </ActionableTitleContentCard>
      </Box>
    </React.Fragment>
  );
};
