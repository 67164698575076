import { updateSelectedBusiness } from "app/app.slice";
import { useFormik } from "formik";
import { useAppSnackbar } from "lib/contexts/SnackbarContext";
import { useAppDispatch } from "lib/store/hooks";
import { useCreateBusinessMutation } from "services/business.slice";

const validate = (values: {
  businessName: string;
  tagline: string;
  phone: string;
  email: string;
  address: string;
  city: string;
  state: string;
  zipcode: string;
  country: string;
  currency: string;
  registrationFee: string;
}) => {
  const errors: {
    businessName?: string;
    tagline?: string;
    phone?: string;
    email?: string;
    address?: string;
    city?: string;
    state?: string;
    zipcode?: string;
    country?: string;
    currency?: string;
    registrationFee?: string;
  } = {};

  if (!values.businessName) {
    errors.businessName = "Required";
  }

  if (!values.tagline) {
    errors.tagline = "Required";
  }

  if (!values.phone) {
    errors.phone = "Required";
  }

  if (!values.email) {
    errors.email = "Required";
  }

  if (!values.address) {
    errors.address = "Required";
  }

  if (!values.city) {
    errors.city = "Required";
  }

  if (!values.state) {
    errors.state = "Required";
  }

  if (!values.zipcode) {
    errors.zipcode = "Required";
  }

  if (!values.country) {
    errors.country = "Required";
  }

  if (!values.currency) {
    errors.currency = "Required";
  }

  if (!values.registrationFee) {
    errors.registrationFee = "Required";
  }

  return errors;
};

export const useCreateBusinessForm = () => {
  const snackbar = useAppSnackbar();
  const [createBusiness] = useCreateBusinessMutation();

  const dispatch = useAppDispatch();

  const formik = useFormik({
    initialValues: {
      businessName: "",
      tagline: "",
      phone: "",
      email: "",
      address: "",
      city: "",
      state: "",
      zipcode: "",
      country: "",
      currency: "",
      registrationFee: "",
    },
    validate,
    onSubmit: async (values) => {
      const response = await createBusiness({
        name: values.businessName,
        tagline: values.tagline,
        address: values.address,
        city: values.city,
        state: values.state,
        zipcode: values.zipcode,
        country: values.country,
        phone: values.phone,
        email: values.email,
        config: {
          currency: values.currency,
          registrationFee: parseFloat(values.registrationFee),
        },
      });

      if ("data" in response) {
        dispatch(
          updateSelectedBusiness({
            business: response.data.business,
          })
        );

        snackbar.show("Business Created Successfully!", {
          variant: "success",
          preventDuplicate: true,
        });

        return;
      }

      if ("error" in response) {
        snackbar.show(response.error as string, {
          variant: "error",
          preventDuplicate: true,
        });
        return;
      }
    },
  });

  return { formik };
};
