import { Typography, Box, Button, TextField } from "@mui/material";
import { NavLink } from "react-router-dom";
import { RegisterWrapper } from "app/register/styles";
import { useRegisterForm } from "app/register/useRegisterForm";
import { Paths } from "data";
import { PasswordField } from "ui/atoms/PasswordField";

export const Register = () => {
  const { formik } = useRegisterForm();

  return (
    <RegisterWrapper>
      <Box display="flex" marginBottom={5}>
        <Typography variant="h6" component="h1" fontWeight={600}>
          Register
        </Typography>
        <Box sx={{ flexGrow: 1 }} />
        <NavLink to={Paths.AUTH.LOGIN}>
          <Button>Already have an account?</Button>
        </NavLink>
      </Box>
      <Box
        component="form"
        onSubmit={formik.handleSubmit}
        display="flex"
        flexDirection="column"
        gap={3}
      >
        <TextField
          name="name"
          label="Name"
          variant="outlined"
          value={formik.values.name}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.name && Boolean(formik.errors.name)}
          helperText={formik.touched.name && formik.errors.name}
          fullWidth
        />

        <TextField
          name="phone"
          label="Phone Number"
          variant="outlined"
          value={formik.values.phone}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.phone && Boolean(formik.errors.phone)}
          helperText={formik.touched.phone && formik.errors.phone}
          fullWidth
        />

        <TextField
          name="email"
          label="Email"
          variant="outlined"
          value={formik.values.email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
          fullWidth
        />

        <PasswordField
          name="password"
          variant="outlined"
          label="Password"
          value={formik.values.password}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.password && Boolean(formik.errors.password)}
          helperText={formik.touched.password && formik.errors.password}
          fullWidth
        />
        <Button type="submit" variant="contained" size="large" fullWidth>
          Register
        </Button>
      </Box>
    </RegisterWrapper>
  );
};
