import { AccessTime, AccountBalance, PeopleAlt } from "@mui/icons-material";
import { Grid } from "@mui/material";
import { Card } from "app/modules/Dashboard/components/Card";
import { useGetCustomerMetricsQuery } from "services/customer.slice";
import { useGetPaymentMetricsQuery } from "services/payments.slice";
import { useGetSubscriptionMetricsQuery } from "services/subscription.slice";
import { useAppSelector } from "lib/store/hooks";
import { selectSelectedBusinessCurrency } from "app/app.slice";
import { Can } from "ui/components/Can/Can";
import { AppPermission } from "lib/constants";

export const MetricCards = () => {
  const currency = useAppSelector(selectSelectedBusinessCurrency);

  const {
    data: customerMetrics,
    isLoading: isLoadingCustomerMetrics,
    isFetching: isFetchingCustomerMetrics,
  } = useGetCustomerMetricsQuery();

  const {
    data: paymentMetrics,
    isLoading: isLoadingPaymentMetrics,
    isFetching: isFetchingPaymentMetrics,
  } = useGetPaymentMetricsQuery();

  const {
    data: subscriptionMetrics,
    isLoading: isLoadingSubscriptionMetrics,
    isFetching: isFetchingSubscriptionMetrics,
  } = useGetSubscriptionMetricsQuery();

  const totalAmountInCurrentMonth = paymentMetrics?.totalAmountInCurrentMonth;

  return (
    <Grid container spacing={3}>
      <Can I={[AppPermission.ViewCustomers]}>
        <Grid item xs={12} sm={6}>
          <Card
            label="Total Collection"
            isLoading={isLoadingPaymentMetrics || isFetchingPaymentMetrics}
            prefix={currency}
            data={paymentMetrics?.totalAmount}
            Icon={AccountBalance}
          />
        </Grid>
      </Can>
      <Can I={[AppPermission.ViewCustomers]}>
        <Grid item xs={12} sm={6}>
          <Card
            label="This Month Collection"
            isLoading={isLoadingPaymentMetrics || isFetchingPaymentMetrics}
            prefix={currency}
            data={totalAmountInCurrentMonth}
            Icon={AccountBalance}
          />
        </Grid>
      </Can>
      <Can I={[AppPermission.ViewCustomerSubscriptions]}>
        <Grid item xs={12} sm={6}>
          <Card
            label="Total Payment Due"
            isLoading={
              isLoadingSubscriptionMetrics || isFetchingSubscriptionMetrics
            }
            prefix={currency}
            data={subscriptionMetrics?.totalDueAmount}
            Icon={AccessTime}
          />
        </Grid>
      </Can>
      <Can I={[AppPermission.ViewCustomers]}>
        <Grid item xs={12} sm={6}>
          <Card
            label="All Members"
            isLoading={isLoadingCustomerMetrics || isFetchingCustomerMetrics}
            data={customerMetrics?.totalCustomers}
            Icon={PeopleAlt}
          />
        </Grid>
      </Can>
      <Can I={[AppPermission.ViewCustomers]}>
        <Grid item xs={12} sm={6}>
          <Card
            label="Total Active Members"
            isLoading={isLoadingCustomerMetrics || isFetchingCustomerMetrics}
            data={customerMetrics?.totalActiveCustomers}
            Icon={PeopleAlt}
          />
        </Grid>
      </Can>
      <Can I={[AppPermission.ViewCustomers]}>
        <Grid item xs={12} sm={6}>
          <Card
            label="Total Inactive Members"
            isLoading={isLoadingCustomerMetrics || isFetchingCustomerMetrics}
            data={customerMetrics?.totalInactiveCustomers}
            Icon={PeopleAlt}
          />
        </Grid>
      </Can>
    </Grid>
  );
};
