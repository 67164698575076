import { AppPermission } from "lib/constants";
import { useMyPermissions } from "lib/hooks/useMyPermissions";
import { isAllowed } from "lib/modules/isActionAllowed";

export const useIsAllowed = ({
  actions,
  type = "ALL",
}: {
  actions: AppPermission[];
  type?: "ANY" | "ALL";
}) => {
  const myPermissions = useMyPermissions();

  return isAllowed({ actions, permissions: myPermissions, type });
};
