import { Box, Divider, Paper, Typography } from "@mui/material";
import React, { ReactElement, ReactNode } from "react";

export const ActionableTitleContentCard = ({
  children,
}: {
  children: ReactNode;
}) => {
  let cardTitle: ReactElement | null = null;
  let cardContent: ReactElement | null = null;
  let cardActions: ReactElement | null = null;

  React.Children.map(children, (child) => {
    if (
      React.isValidElement(child) &&
      child.type === ActionableTitleContentCard.Title
    ) {
      cardTitle = child;
    } else if (
      React.isValidElement(child) &&
      child.type === ActionableTitleContentCard.Content
    ) {
      cardContent = child;
    } else if (
      React.isValidElement(child) &&
      child.type === ActionableTitleContentCard.Actions
    ) {
      cardActions = child;
    } else {
      throw new Error(
        "ActionableTitleContentCard only accepts ActionableTitleContentCard.Title, ActionableTitleContentCard.Content, and ActionableTitleContentCard.Actions as children."
      );
    }
  });

  return (
    <Paper elevation={3}>
      <Box paddingX={3} paddingY={2} sx={{ cursor: "default" }}>
        {cardTitle ? (
          // @ts-expect-error - figure out ts error
          <cardTitle.type {...cardTitle.props}>
            {/* @ts-expect-error - figure out ts error */}
            {cardTitle.props.children}
            {/* @ts-expect-error - figure out ts error */}
          </cardTitle.type>
        ) : null}
      </Box>
      <Divider />
      {cardContent ? (
        // @ts-expect-error - figure out ts error
        <cardContent.type {...cardContent.props}>
          {/* @ts-expect-error - figure out ts error */}
          {cardContent.props.children}
          {/* @ts-expect-error - figure out ts error */}
        </cardContent.type>
      ) : null}
      {cardActions ? <Divider /> : null}
      {cardActions ? (
        // @ts-expect-error - figure out ts error
        <cardActions.type {...cardActions.props}>
          {/* @ts-expect-error - figure out ts error */}
          {cardActions.props.children}
          {/* @ts-expect-error - figure out ts error */}
        </cardActions.type>
      ) : null}
    </Paper>
  );
};

ActionableTitleContentCard.Title = ({ children }: { children: string }) => {
  return <Typography variant="h6">{children}</Typography>;
};

ActionableTitleContentCard.Content = ({
  children,
}: {
  children: ReactNode;
}) => {
  return (
    <Box paddingX={3} paddingY={4}>
      {children}
    </Box>
  );
};

ActionableTitleContentCard.Actions = ({
  children,
}: {
  children: ReactNode;
}) => {
  return (
    <Box display="flex" justifyContent="flex-end" gap={2} padding={3}>
      {children}
    </Box>
  );
};
