import { Add } from "@mui/icons-material";
import { Box, Button, Paper, Typography } from "@mui/material";
import { Paths } from "data";
import { useNavigate } from "react-router-dom";

export const CreateBusinessNotification = () => {
  const navigate = useNavigate();

  const handleCreateBusinessClick = () => {
    navigate(Paths.BUSINESS.BASE);
  };

  return (
    <Box
      width="100%"
      height="100%"
      padding={2}
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Paper
        elevation={3}
        sx={{ padding: 4, width: { xs: "100%", sm: "500px" } }}
      >
        <Typography variant="h5" component="h1" fontWeight="bold" gutterBottom>
          Hi! Create Your Business
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          There are no registered businesses under your account yet. Click here
          to create your business.
        </Typography>
        <Button
          variant="contained"
          size="large"
          sx={{ marginTop: 1 }}
          endIcon={<Add />}
          onClick={handleCreateBusinessClick}
          fullWidth
        >
          Create Business
        </Button>
      </Paper>
    </Box>
  );
};
