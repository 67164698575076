import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppLoadingStatus } from "data";
import { AppLoadingStatusType } from "types/utility-types";
import { TransformedAppData, UserDetails } from "types/TransformedAppData";
import { Business, BusinessDetails } from "types/Business";
import { UserProfile } from "types/User";
import type { RootState } from "../lib/store/types";

// Define a type for the slice state
export interface AppState {
  status: AppLoadingStatusType;
  user: UserDetails;
  business: BusinessDetails;
}

// Define the initial state using that type
export const initialAppState: AppState = {
  status: AppLoadingStatus.LOADING,
  user: {
    id: -1,
    email: "",
    phone: "",
    createdAt: "",
    updatedAt: "",
    isLoggedIn: false,
    businessRole: null,
    profile: {} as UserProfile,
  },
  business: {
    selectedBusiness: {} as Business,
    businesses: [],
  },
};

export const appSlice = createSlice({
  name: "app",
  initialState: initialAppState,
  reducers: {
    setAppData: (state, action: PayloadAction<TransformedAppData>) => {
      state.user = action.payload.user;
      state.business = action.payload.business;
    },
    resetAppData: (state, action: PayloadAction<undefined>) => {
      state.business = initialAppState.business;
      state.user = initialAppState.user;
      state.status = AppLoadingStatus.IDLE;
    },
    setAppStatus: (state, action: PayloadAction<AppLoadingStatusType>) => {
      state.status = action.payload;
    },
    updateSelectedBusiness: (
      state,
      action: PayloadAction<{ business: Business }>
    ) => {
      state.business.selectedBusiness = action.payload.business;

      const user = action.payload.business.users.find((user) => {
        return user.id === state.user.id;
      });

      if (user) {
        state.user.businessRole = user.role;
      }

      const businessIndex = state.business.businesses.findIndex(
        (business) => business.id === action.payload.business.id
      );

      if (businessIndex === -1) {
        state.business.businesses = [
          ...state.business.businesses,
          action.payload.business,
        ];
      } else {
        state.business.businesses[businessIndex] = action.payload.business;
      }
    },
  },
});

export const {
  setAppStatus,
  setAppData,
  resetAppData,
  updateSelectedBusiness,
} = appSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectIsLoggedIn = (state: RootState) => state.app.user.isLoggedIn;
export const selectStatus = (state: RootState) => state.app.status;
export const selectUser = (state: RootState) => state.app.user;
export const selectBusinesses = (state: RootState) =>
  state.app.business.businesses;
export const selectSelectedBusinessId = (state: RootState) =>
  state.app.business.selectedBusiness?.id;
export const selectSelectedBusiness = (state: RootState) =>
  state.app.business.selectedBusiness;
export const selectSelectedBusinessCurrency = (state: RootState) =>
  state.app.business.selectedBusiness?.currency;

export default appSlice.reducer;
