import { Box, Paper, Skeleton, Typography } from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

const CardHeight = "125px";

export const Card = ({
  isLoading,
  label,
  data,
  Icon,
  prefix,
}: {
  isLoading: boolean;
  label: string;
  data?: number;
  Icon: React.ElementType;
  prefix?: string;
}) => {
  if (isLoading) {
    return (
      <Skeleton
        sx={{ borderRadius: "10px" }}
        variant="rectangular"
        width="100%"
        height="125px"
      />
    );
  }

  if (data === undefined || data < 0) {
    return (
      <Paper
        sx={{
          borderRadius: "10px",
          height: CardHeight,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <ErrorOutlineIcon />
        <Typography variant="body1" marginLeft={1}>
          Something went wrong
        </Typography>
      </Paper>
    );
  }

  return (
    <Paper sx={{ borderRadius: "10px" }}>
      <Box paddingX={3} paddingY={3} position="relative" height={CardHeight}>
        <Typography variant="subtitle2">{label}</Typography>
        <Typography
          marginTop={1}
          variant="body1"
          sx={{ fontWeight: 600, fontSize: 32 }}
        >
          {prefix} {data.toLocaleString()}
        </Typography>
        <Box position="absolute" right={40} top={20}>
          <Icon sx={{ color: "action.disabledBackground", fontSize: 80 }} />
        </Box>
      </Box>
    </Paper>
  );
};
