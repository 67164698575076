import { Plan } from "types/Plan";
import { TagTypesMap } from "services/constants";
import { apiSlice } from "./api.slice";

const planSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createPlan: builder.mutation<
      { plan: Plan },
      {
        name: string;
        description?: string;
        minPrice: number;
        maxPrice: number;
        intervalCount: number;
        intervalUnit: string;
        startDate: string;
        endDate: string;
        isRecurring: boolean;
        isRegistrationFeeRequired: boolean;
      }
    >({
      query: (body) => ({
        url: "/plans",
        method: "POST",
        data: body,
      }),
      invalidatesTags: [TagTypesMap.Plan],
    }),
    getAllPlans: builder.query<Plan[], undefined>({
      query: () => ({
        url: "/plans",
        method: "GET",
      }),
      providesTags: [TagTypesMap.Plan],
    }),
  }),
});

export const { useCreatePlanMutation, useGetAllPlansQuery } = planSlice;
