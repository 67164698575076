import { getShouldShowSessionExpiredDialog } from "lib/helpers/getShouldShowSessionExpiredDialog";
import { useCallback } from "react";
import { useLocation } from "react-router-dom";
import { useGetAllBusinessesMutation } from "services/business.slice";
import { useLazyGetRolesAndPermissionsQuery } from "services/team.slice";
import { useGetUserMutation } from "services/user.slice";
import { RawAppData } from "types/RawAppData";

export const useGetAppData = () => {
  const location = useLocation();
  const [getUser] = useGetUserMutation();
  const [getAllBusinesses] = useGetAllBusinessesMutation();
  const [getRolesAndPermissions] = useLazyGetRolesAndPermissionsQuery();

  const getDataAtSignIn = useCallback(async (): Promise<RawAppData> => {
    const skipExpiredSessionCheck = getShouldShowSessionExpiredDialog(
      location.pathname
    );

    try {
      const [userResponse, businessResponse] = await Promise.all([
        getUser({
          skipExpiredSessionCheck,
        }).unwrap(),
        getAllBusinesses({
          skipExpiredSessionCheck,
        }).unwrap(),
        getRolesAndPermissions(),
      ]);

      if (userResponse.user && !userResponse.profile) {
        return Promise.reject("User profile not found");
      }

      return Promise.resolve({
        user: userResponse.user,
        userProfile: userResponse.profile,
        businesses: businessResponse.businesses,
        selectedBusinessId: businessResponse.selectedBusinessId,
      });
    } catch (error) {
      return Promise.reject(error);
    }
  }, [getAllBusinesses, getRolesAndPermissions, getUser, location.pathname]);

  const getDataAtReload = useCallback(async (): Promise<RawAppData> => {
    const skipExpiredSessionCheck = true;

    try {
      const userResponse = await getUser({
        skipExpiredSessionCheck,
      }).unwrap();

      if (userResponse.user) {
        if (!userResponse.profile) {
          return Promise.reject("User profile not found");
        }

        const [businessResponse] = await Promise.all([
          getAllBusinesses({
            skipExpiredSessionCheck,
          }).unwrap(),
          getRolesAndPermissions(),
        ]);

        return Promise.resolve({
          user: userResponse.user,
          userProfile: userResponse.profile,
          businesses: businessResponse.businesses,
          selectedBusinessId: businessResponse.selectedBusinessId,
        });
      } else {
        return Promise.reject("User not found");
      }
    } catch (error) {
      return Promise.reject(error);
    }
  }, [getAllBusinesses, getRolesAndPermissions, getUser]);

  return {
    getDataAtSignIn,
    getDataAtReload,
  };
};
