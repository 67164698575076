import { Add } from "@mui/icons-material";
import { Fab } from "@mui/material";
import { AddPlanDialog } from "app/modules/Plans/AddPlan/AddPlan";
import React, { useState } from "react";

export const AddPlanAction = () => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  return (
    <React.Fragment>
      <Fab
        sx={{ position: "absolute", bottom: 50, right: 50 }}
        size="large"
        color="primary"
        aria-label="create plan"
        onClick={() => setIsDialogOpen(true)}
      >
        <Add />
      </Fab>
      <AddPlanDialog
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
      />
    </React.Fragment>
  );
};
