import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

export const ConfirmDeleteInvite = ({
  isLoading,
  isOpen,
  onClose,
  onDeleteInvite,
}: {
  isLoading: boolean;
  isOpen: boolean;
  onClose: () => void;
  onDeleteInvite: () => void;
}) => {
  return (
    <Dialog
      onClose={(event, reason) => {
        if (reason === "backdropClick") return;
        onClose();
      }}
      open={isOpen}
    >
      <DialogTitle>Delete Invite</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to delete this invite?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button size="medium" onClick={onClose}>
          No
        </Button>
        <LoadingButton
          color="error"
          size="medium"
          variant="contained"
          onClick={onDeleteInvite}
          loading={isLoading}
          autoFocus
        >
          Yes
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
