import { createContext, useContext, useMemo, useState } from "react";
import { PaletteMode, ThemeProvider, useMediaQuery } from "@mui/material";
import { createAppTheme } from "lib/theme";

export type CustomThemeContextType = {
  toggleMode: () => void;
};

const CustomThemeContext = createContext<CustomThemeContextType>({
  toggleMode: () => {},
});

export const CustomThemeProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");

  const [mode, setMode] = useState<PaletteMode>();

  const themeConfig = useMemo(
    () =>
      createAppTheme({ mode: mode || (prefersDarkMode ? "dark" : "light") }),
    [mode, prefersDarkMode]
  );

  const toggleMode = () => {
    setMode((prev) => (prev === "light" ? "dark" : "light"));
  };

  return (
    <CustomThemeContext.Provider value={{ toggleMode }}>
      <ThemeProvider theme={themeConfig}>{children}</ThemeProvider>
    </CustomThemeContext.Provider>
  );
};

export const useCustomTheme = () => {
  if (!CustomThemeContext) {
    throw new Error("useCustomTheme must be used within a CustomThemeProvider");
  }

  return useContext(CustomThemeContext);
};
