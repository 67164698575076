import {
  CreateSubscriptionPaymentRequest,
  SubscriptionPayment,
  SubscriptionPaymentWithPaymentDetailsList,
} from "types/SubscriptionPayment";
import { TagTypesMap } from "services/constants";
import { apiSlice } from "./api.slice";

const subscriptionSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllSubscriptionPayments: builder.query<
      SubscriptionPaymentWithPaymentDetailsList,
      void
    >({
      query: () => ({
        url: "/subscription-payments",
        method: "GET",
      }),
      providesTags: [TagTypesMap.SubscriptionPayments],
    }),
    createSubscriptionPayment: builder.mutation<
      SubscriptionPayment,
      CreateSubscriptionPaymentRequest
    >({
      query: (data) => ({
        url: "/subscription-payments",
        method: "POST",
        data,
      }),
      invalidatesTags: [TagTypesMap.SubscriptionPayments],
    }),
  }),
});

export const {
  useGetAllSubscriptionPaymentsQuery,
  useCreateSubscriptionPaymentMutation,
} = subscriptionSlice;
