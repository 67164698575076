import { Box } from "@mui/material";
import { DataGrid, GridColDef, GridRowsProp } from "@mui/x-data-grid";
import { AppPermission } from "lib/constants";
import { useGetAllSubscriptionPaymentsQuery } from "services/subscriptionPayments.slice";
import {
  SubscriptionPaymentWithPaymentDetails,
  SubscriptionPaymentWithPaymentDetailsList,
} from "types/SubscriptionPayment";
import { Can } from "ui/components/Can/Can";
import { PageSpinner } from "ui/components/Loader/PageSpinner";

//TODO: update columns and column width

const getTableRows = (
  data?: SubscriptionPaymentWithPaymentDetailsList
): GridRowsProp => {
  if (!data) return [];

  return data.map(
    (subscriptionPayment: SubscriptionPaymentWithPaymentDetails) => ({
      id: subscriptionPayment.id,
      memberName: subscriptionPayment.Customer.member.memberDetails.name,
      planName: subscriptionPayment.CustomerSubscription.plan.name,
      paymentId: subscriptionPayment.Payment.paymentId,
      paymentAmount: subscriptionPayment.Payment.amount,
      paymentStatus: subscriptionPayment.Payment.status,
      paymentDate: new Date(subscriptionPayment.Payment.createdAt),
      paymentMethod: subscriptionPayment.Payment.paymentMethod,
    })
  );
};

//TODO: add more fields to show on need basis and quick actions
const columns: GridColDef[] = [
  { field: "id", headerName: "ID", width: 50 },
  { field: "memberName", headerName: "Member Name" },
  { field: "planName", headerName: "Plan", width: 150 },
  { field: "paymentId", headerName: "Payment ID", width: 150 },
  { field: "paymentAmount", headerName: "Payment Amount", width: 150 },
  { field: "paymentStatus", headerName: "Payment Status", width: 150 },
  {
    field: "paymentDate",
    headerName: "Payment Date",
    width: 150,
    type: "date",
  },
  { field: "paymentMethod", headerName: "Payment Method", width: 150 },
];

const CustomNoRowsOverlay = () => {
  return (
    <Box
      width="100%"
      height="100%"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      No Rows Found
    </Box>
  );
};

export const AllMembershipPayments = () => {
  const { data, isLoading } = useGetAllSubscriptionPaymentsQuery();

  const rows = getTableRows(data);

  if (isLoading) {
    return <PageSpinner />;
  }

  return (
    <Can
      I={[AppPermission.ViewCustomerSubscriptions]}
      strict={false}
      variant="PAGE"
    >
      <Box padding={{ xs: 2, sm: 3 }} width="100%" height="700px">
        <DataGrid
          rows={rows}
          columns={columns}
          slots={{ noRowsOverlay: CustomNoRowsOverlay }}
          checkboxSelection
        />
      </Box>
    </Can>
  );
};
