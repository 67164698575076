import { Paper, Stack, Typography } from "@mui/material";
import { MemberWithMemberDetails } from "services/types/member.types";
import { Field } from "ui/atoms/Field";
import { toTitleCase } from "lib/utils/toTitleCase";
import { AccountCircle } from "@mui/icons-material";

export const MemberSummary = ({
  details,
}: {
  details: MemberWithMemberDetails;
}) => {
  return (
    <Paper elevation={3}>
      <Stack padding={2} direction="row" alignItems="center" spacing={1}>
        <AccountCircle />
        <Typography variant="h6">{details.memberDetails.name}</Typography>
      </Stack>
      <Stack padding={3} spacing={3}>
        <Field label="Phone" value={details.memberDetails.phone} />
        <Field
          label="Gender"
          value={toTitleCase(details.memberDetails.gender) || "-"}
        />
        <Field label="Email" value={details.memberDetails.email || "-"} />
      </Stack>
    </Paper>
  );
};
