import React from "react";
import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemText,
  Paper,
  Skeleton,
  Typography,
  useTheme,
} from "@mui/material";
import dayjs from "dayjs";
import { useGetExpiringSubscriptionsQuery } from "services/subscription.slice";

const Header = () => {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === "dark";

  return (
    <Box
      paddingX={2}
      paddingY={1}
      sx={{
        backgroundColor: isDarkMode
          ? theme.palette.action.disabledBackground
          : theme.palette.primary.main,
        color: isDarkMode
          ? theme.palette.text.primary
          : theme.palette.common.white,
        borderTopLeftRadius: 10,
        borderTopRightRadius: 10,
      }}
    >
      <Typography variant="h6" sx={{ fontSize: 18, fontWeight: 700 }}>
        Memberships expiring soon
      </Typography>
    </Box>
  );
};

export const MembershipExpiringCard = () => {
  const {
    data: expiringSubscriptions,
    isLoading: isLoadingExpiringSubscriptions,
    isFetching: isFetchingExpiringSubscriptions,
  } = useGetExpiringSubscriptionsQuery();

  if (isLoadingExpiringSubscriptions || isFetchingExpiringSubscriptions) {
    return <Skeleton variant="rectangular" height="200px" width="100%" />;
  }

  if (!expiringSubscriptions?.length) {
    return (
      <Paper elevation={2}>
        <Header />
        <Box
          padding={2}
          height="100px"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Typography variant="body2" color="textSecondary">
            No memberships expiring soon
          </Typography>
        </Box>
      </Paper>
    );
  }

  return (
    <Paper elevation={2}>
      <Header />
      <List
        sx={{
          width: "100%",
          minHeight: "100px",
          maxHeight: "300px",
          overflowY: "auto",
        }}
      >
        {expiringSubscriptions?.map((subscription, index) => (
          <React.Fragment key={subscription.id}>
            <ListItem key={subscription.id} sx={{ overflowY: "auto" }}>
              <ListItemText sx={{ whiteSpace: "nowrap" }}>
                <Typography component="span" fontWeight={600}>
                  {subscription.Customer.member.memberDetails.name}'s
                </Typography>{" "}
                membership expiring in{" "}
                <Typography component="span" fontWeight={600}>
                  {dayjs(subscription.endDate).diff(dayjs(), "day")} days
                </Typography>
              </ListItemText>
            </ListItem>
            {index !== expiringSubscriptions.length - 1 ? <Divider /> : null}
          </React.Fragment>
        ))}
      </List>
    </Paper>
  );
};
