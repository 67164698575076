import {
  Dialog,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from "@mui/material";
import FitnessCenterIcon from "@mui/icons-material/FitnessCenter";
import { useGetBusiness } from "lib/hooks/useGetBusiness";
import { useSwitchBusinessMutation } from "services/business.slice";
import { Business } from "types/Business";
import { useAppSnackbar } from "lib/contexts/SnackbarContext";

export const SwitchBusinessDialog = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  const { businesses, selectedBusiness } = useGetBusiness();

  const snackbar = useAppSnackbar();

  const [switchBusiness] = useSwitchBusinessMutation();

  const handleSwitchBusiness = async (business: Business) => {
    const response = await switchBusiness({ id: business.id }).unwrap();

    if (response.message) {
      //TODO: use useInitiateApp() to reinitialize the app instead of reloading the page
      window.location.reload();
    } else {
      snackbar.show("Failed to switch business", { variant: "error" });
    }
  };

  return (
    <Dialog maxWidth="sm" open={isOpen} onClose={onClose} fullWidth>
      <DialogTitle>Select Gym</DialogTitle>
      <DialogContent>
        <List>
          {businesses.map((business) => (
            <ListItem disablePadding key={business.id}>
              {business.id === selectedBusiness?.id ? (
                <Tooltip title="Already Selected">
                  <span>
                    <ListItemButton disabled>
                      <ListItemIcon>
                        <FitnessCenterIcon />
                      </ListItemIcon>
                      <ListItemText primary={business.name} />
                      {/* TODO: show role for the business */}
                    </ListItemButton>
                  </span>
                </Tooltip>
              ) : (
                <ListItemButton onClick={() => handleSwitchBusiness(business)}>
                  <ListItemIcon>
                    <FitnessCenterIcon />
                  </ListItemIcon>
                  <ListItemText primary={business.name} />
                  {/* TODO: show role for the business */}
                </ListItemButton>
              )}
            </ListItem>
          ))}
        </List>
      </DialogContent>
    </Dialog>
  );
};
