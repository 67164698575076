import { LoadingButton } from "@mui/lab";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Dialog,
  FormControl,
  FormHelperText,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { DatePicker } from "@mui/x-date-pickers";
import { useAddMembershipForm } from "app/modules/Members/AddMembership/hooks/useAddMembershipForm";
import { ActionableTitleContentCard } from "ui/atoms/ActionableTitleContentCard";
import { Field } from "ui/atoms/Field";
import { getPlanDetails } from "app/modules/Members/AddMembership/helpers";
import { CustomerWithMemberAndMemberDetails } from "services/types/member.types";
import { useAppSelector } from "lib/store/hooks";
import { selectSelectedBusinessCurrency } from "app/app.slice";
import { useGetAllPlansQuery } from "services/plan.slice";
import { PageSpinner } from "ui/components/Loader/PageSpinner";

export const AddMembershipForm = ({
  selectedCustomer,
  open = false,
  onSuccess,
  onClose,
}: {
  selectedCustomer: CustomerWithMemberAndMemberDetails;
  open?: boolean;
  onSuccess: () => void;
  onClose: () => void;
}) => {
  const currency = useAppSelector(selectSelectedBusinessCurrency);

  const { data: plans = [], isLoading: isAllPlansLoading } =
    useGetAllPlansQuery(undefined);

  const { formik } = useAddMembershipForm({
    onSuccess: (_: number) => {
      onSuccess();
    },
    customerId: selectedCustomer.id,
  });

  const handleStartDateChange = (date: Dayjs | null) => {
    formik.setFieldValue("startDate", date);
  };

  const handleEndDateChange = (date: Dayjs | null) => {
    formik.setFieldValue("endDate", date);
  };

  const handlePlanChange = (event: SelectChangeEvent<string>) => {
    const planId = event.target.value;
    const planDetails = getPlanDetails(plans, planId);

    if (!planDetails) return;

    formik.setFieldValue("planId", planId);
    formik.setFieldValue("price", planDetails.minPrice);
    formik.setFieldValue(
      "endDate",
      dayjs().add(planDetails.intervalCount, planDetails.intervalUnit)
    );
  };

  const planDetails = getPlanDetails(plans, formik.values.planId);

  return (
    <Dialog open={open} onClose={onClose}>
      <Box component="form" onSubmit={formik.handleSubmit}>
        <ActionableTitleContentCard>
          <ActionableTitleContentCard.Title>
            Membership details
          </ActionableTitleContentCard.Title>

          {isAllPlansLoading ? (
            <PageSpinner />
          ) : (
            <ActionableTitleContentCard.Content>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <FormControl
                    error={!!formik.touched.planId && !!formik.errors.planId}
                    fullWidth
                  >
                    <InputLabel>Plan</InputLabel>
                    <Select
                      name="planId"
                      label="Plan"
                      fullWidth
                      value={formik.values.planId}
                      onChange={handlePlanChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.planId && Boolean(formik.errors.planId)
                      }
                    >
                      {plans.map((plan) => (
                        <MenuItem key={plan.id} value={plan.id}>
                          {plan.name}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>
                      {formik.touched.planId && formik.errors.planId}
                    </FormHelperText>
                  </FormControl>
                </Grid>

                {formik.values.planId ? (
                  <Grid item xs={12} marginTop="-15px">
                    <Accordion defaultExpanded>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                      >
                        Plan Details
                      </AccordionSummary>
                      <AccordionDetails>
                        <Box display="flex" justifyContent="space-between">
                          <Field
                            label="Minimum Price"
                            value={planDetails?.minPrice}
                          />
                          <Field
                            label="Minimum Price"
                            value={planDetails?.maxPrice}
                          />
                          <Field
                            label="Registration Fee Required"
                            value={
                              planDetails?.isRegistrationFeeRequired
                                ? "Yes"
                                : "No"
                            }
                          />
                        </Box>
                      </AccordionDetails>
                    </Accordion>
                  </Grid>
                ) : null}

                <Grid item xs={12} md={6}>
                  <DatePicker
                    name="startDate"
                    label="Start Date"
                    sx={{ width: "100%" }}
                    value={formik.values.startDate}
                    onChange={handleStartDateChange}
                    slotProps={{
                      textField: {
                        error: !!formik.errors.startDate,
                        helperText: formik.errors.startDate,
                      },
                    }}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <DatePicker
                    name="endDate"
                    label="End Date"
                    sx={{ width: "100%" }}
                    value={formik.values.endDate}
                    onChange={handleEndDateChange}
                    slotProps={{
                      textField: {
                        error: !!formik.errors.endDate,
                        helperText: formik.errors.endDate,
                      },
                    }}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <FormControl
                    fullWidth
                    error={formik.touched.price && Boolean(formik.errors.price)}
                  >
                    <InputLabel>Price</InputLabel>
                    <OutlinedInput
                      name="price"
                      label="Price"
                      type="number"
                      startAdornment={
                        <InputAdornment position="start">
                          {currency}
                        </InputAdornment>
                      }
                      value={formik.values.price}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    <FormHelperText>
                      {formik.touched.price && formik.errors.price}
                    </FormHelperText>
                  </FormControl>
                </Grid>
              </Grid>
            </ActionableTitleContentCard.Content>
          )}
          <ActionableTitleContentCard.Actions>
            <Button onClick={onClose}>Cancel</Button>
            <LoadingButton
              type="submit"
              variant="contained"
              size="large"
              loading={formik.isSubmitting || isAllPlansLoading}
            >
              <span>Save</span>
            </LoadingButton>
          </ActionableTitleContentCard.Actions>
        </ActionableTitleContentCard>
      </Box>
    </Dialog>
  );
};
