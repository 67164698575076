import { Autocomplete, Box, Divider, TextField } from "@mui/material";
import React from "react";
import { OptionType } from "ui/components/ModuleSearch/types";

interface Props<T> {
  options: OptionType<T>[];
  searchLabel: string;
  onChange: (value: OptionType<T> | null) => void;
}

export const ModuleSearch = <T extends unknown>({
  options = [],
  searchLabel = "Search..",
  onChange,
}: Props<T>) => {
  return (
    <React.Fragment>
      <Box width="100%">
        <Autocomplete
          disablePortal
          getOptionLabel={(option: OptionType<T>) => option.label}
          options={options}
          renderInput={(params) => (
            <TextField variant="outlined" {...params} label={searchLabel} />
          )}
          onChange={(event: any, value) => {
            onChange(value);
          }}
          disableClearable
        />
      </Box>
    </React.Fragment>
  );
};
