import { useRef, useState } from "react";
import dayjs from "dayjs";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Box, Chip, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import {
  StyledTableCell,
  StyledTableRow,
} from "app/modules/Members/ViewMembership/styles";
import {
  CustomerSubscriptionStatus as CustomerSubscriptionStatusType,
  SubscriptionWithPlanDetails,
} from "types/Subscription";
import { toTitleCase } from "lib/utils/toTitleCase";
import { CustomerSubscriptionStatus } from "lib/constants";
import {
  useCancelSubscriptionMutation,
  useSendInvoiceToCustomerMutation,
} from "services/subscription.slice";
import { ConfirmCancelMembershipDialog } from "app/modules/Members/ViewMembership/ConfirmCancelMembershipDialog";
import { useAppSelector } from "lib/store/hooks";
import { selectSelectedBusinessCurrency } from "app/app.slice";
import { Send } from "@mui/icons-material";
import { useAppSnackbar } from "lib/contexts/SnackbarContext";

function createData({
  amountPaid,
  dueAmount,
  endDate,
  planName,
  price,
  startDate,
  status,
  onCancelSubscription,
  onSendInvoice,
  isCanceling,
  isCancelActionVisible,
  isDownloadInvoiceVisible,
}: {
  planName: string;
  price: number;
  startDate: string;
  endDate: string;
  status: CustomerSubscriptionStatusType;
  amountPaid: number;
  dueAmount: number;
  isCanceling: boolean;
  onCancelSubscription: () => void;
  onSendInvoice: () => void;
  isCancelActionVisible: boolean;
  isDownloadInvoiceVisible: boolean;
}) {
  return {
    planName,
    price,
    startDate: dayjs(new Date(startDate)).format("DD MMM YYYY"),
    endDate: dayjs(new Date(endDate)).format("DD MMM YYYY"),
    amountPaid,
    dueAmount,
    status: (
      <Chip
        size="small"
        label={toTitleCase(status)}
        color={
          status === CustomerSubscriptionStatus.ACTIVE ? "success" : "error"
        }
      />
    ),
    actions: (
      <Box display="flex" columnGap={1}>
        {isCancelActionVisible ? (
          <LoadingButton
            loading={isCanceling}
            variant="outlined"
            color="error"
            size="small"
            onClick={onCancelSubscription}
          >
            Cancel
          </LoadingButton>
        ) : null}
        {isDownloadInvoiceVisible ? (
          <LoadingButton
            variant="outlined"
            color="primary"
            size="small"
            onClick={onSendInvoice}
            endIcon={<Send />}
          >
            Invoice
          </LoadingButton>
        ) : null}
      </Box>
    ),
  };
}

export const MembershipListTable = ({
  customerSubscriptionList,
  isCancelActionVisible,
  isDownloadInvoiceVisible,
}: {
  customerSubscriptionList: SubscriptionWithPlanDetails[];
  isCancelActionVisible: boolean;
  isDownloadInvoiceVisible: boolean;
}) => {
  const snackbar = useAppSnackbar();

  const currency = useAppSelector(selectSelectedBusinessCurrency);

  const [
    isConfirmCancelMembershipModalOpen,
    setIsConfirmCancelMembershipModalOpen,
  ] = useState(false);

  const [cancelSubscription, { isLoading: isCanceling }] =
    useCancelSubscriptionMutation();
  const [sendInvoice, { isLoading: isSendingInvoice }] =
    useSendInvoiceToCustomerMutation();

  const currentLoadingCustomerSubscriptionIdRef = useRef<number | null>(null);

  const handleCancelSubscription = () => {
    if (!currentLoadingCustomerSubscriptionIdRef.current) return;

    cancelSubscription({
      customerSubscriptionId: currentLoadingCustomerSubscriptionIdRef.current,
    });
    setIsConfirmCancelMembershipModalOpen(false);
  };

  const handleSendInvoice = async (id: number) => {
    const response = await sendInvoice({
      customerSubscriptionId: id,
    }).unwrap();

    if ("message" in response) {
      snackbar.show(response.message, {
        variant: "success",
      });
    } else {
      snackbar.show("Something went wrong", {
        variant: "error",
      });
    }
  };

  if (customerSubscriptionList.length === 0) {
    return (
      <Paper sx={{ padding: 2 }}>
        <Box textAlign="center">
          <Typography variant="body1">No memberships found</Typography>
        </Box>
      </Paper>
    );
  }

  const rows = customerSubscriptionList.map((subscription) => {
    return createData({
      planName: subscription.plan.name,
      price: subscription.price,
      startDate: subscription.startDate,
      endDate: subscription.endDate,
      status: subscription.status,
      amountPaid: subscription.amountPaid,
      dueAmount: subscription.dueAmount,
      isCancelActionVisible,
      isDownloadInvoiceVisible,
      isCanceling:
        currentLoadingCustomerSubscriptionIdRef.current === subscription.id &&
        isCanceling,
      onCancelSubscription: () => {
        currentLoadingCustomerSubscriptionIdRef.current = subscription.id;
        setIsConfirmCancelMembershipModalOpen(true);
      },
      onSendInvoice: () => handleSendInvoice(subscription.id),
    });
  });

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="all memberships">
        <TableHead>
          <TableRow>
            <StyledTableCell>Membership plan</StyledTableCell>
            <StyledTableCell align="right">Start Date</StyledTableCell>
            <StyledTableCell align="right">End Date</StyledTableCell>
            <StyledTableCell align="right">Price</StyledTableCell>
            <StyledTableCell align="right">Amount Paid</StyledTableCell>
            <StyledTableCell align="right">Due Amount</StyledTableCell>
            <StyledTableCell align="center">Status</StyledTableCell>
            <StyledTableCell align="left">Actions</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <StyledTableRow key={row.planName}>
              <StyledTableCell component="th" scope="row">
                {row.planName}
              </StyledTableCell>
              <StyledTableCell align="right">{row.startDate}</StyledTableCell>
              <StyledTableCell align="right">{row.endDate}</StyledTableCell>
              <StyledTableCell align="right">
                {currency} {row.price}
              </StyledTableCell>
              <StyledTableCell align="right">{row.amountPaid}</StyledTableCell>
              <StyledTableCell align="right">
                {currency} {row.dueAmount}
              </StyledTableCell>
              <StyledTableCell align="center">{row.status}</StyledTableCell>
              <StyledTableCell align="center">{row.actions}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>

      <ConfirmCancelMembershipDialog
        isOpen={isConfirmCancelMembershipModalOpen}
        onClose={() => setIsConfirmCancelMembershipModalOpen(false)}
        onCancelMembership={handleCancelSubscription}
      />
    </TableContainer>
  );
};
