import { Modules } from "app/modules/module-constants";
import { Module, SubModule } from "app/modules/types";
import { useLocation } from "react-router-dom";

const getCurrentModulesName = (
  pathname: string
): {
  module: Module | null;
  subModule: SubModule | null;
} => {
  let currentModule = null;
  let currentSubModule = null;

  Object.values(Modules).forEach((module) => {
    if (module.url === pathname) {
      currentModule = module;
      return;
    }

    if (module.subModules) {
      Object.values(module.subModules).forEach((subModule) => {
        if (subModule.url === pathname) {
          currentSubModule = subModule;
          currentModule = module;
          return;
        }
      });
    }
  });

  return {
    module: currentModule,
    subModule: currentSubModule,
  };
};

export const useCurrentModule = () => {
  const location = useLocation();
  return getCurrentModulesName(location.pathname);
};

export const useCurrentModuleName = () => {
  const { module, subModule } = useCurrentModule();
  return subModule?.title || module?.title || "";
};
