const globals: Record<string, any> = {
  apiHost: `${window.location.protocol}//${process.env.REACT_APP_API_HOST}/api`,
  // env: __ENV__,
  // isProd: __ENV__ === "prod",
};

const getGlobals = {
  get(keyName: string) {
    return !globals.hasOwnProperty(keyName) ? void 0 : globals[keyName];
  },
  set(keyName: string, value: any) {
    return (globals[keyName] = value);
  },
};
export default getGlobals;
