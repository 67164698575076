import { TagTypesMap } from "services/constants";
import {
  GetLeadsResponse,
  GetLeadsListResponse,
} from "services/types/lead.type";
import { apiSlice } from "./api.slice";

const paymentsSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllLeads: builder.query<GetLeadsListResponse, void>({
      query: () => ({
        url: "/leads",
        method: "GET",
      }),
      providesTags: [TagTypesMap.Leads],
    }),
    createLeads: builder.query<GetLeadsResponse, void>({
      query: () => ({
        url: "/leads",
        method: "GET",
      }),
      providesTags: [TagTypesMap.Leads],
    }),
  }),
});

export const { useGetAllLeadsQuery, useCreateLeadsQuery } = paymentsSlice;
