import dayjs from "dayjs";
import { PaymentMetrics } from "services/types/payment.types";

export const getTotalPaymentSeriesData = (
  data: PaymentMetrics["totalPaymentsPerMonth"]
) => {
  const sortedTotalPaymentPerMonthData = Object.entries(data).sort((a, b) => {
    return dayjs(a[0], "YYYY-M").diff(dayjs(b[0], "YYYY-M"));
  });

  const totalPaymentsPerMonthSeriesData = sortedTotalPaymentPerMonthData.map(
    (data) => data[1]
  );

  return Array.from(
    { length: 6 },
    (_, i) => totalPaymentsPerMonthSeriesData[6 - i - 1] || null
  );
};
