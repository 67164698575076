import { AppEvents } from "data";

type AuthCustomEventListeners = {
  initiateApp: ({
    type,
    onSuccess,
  }: {
    type: "SIGN_IN" | "RELOAD";
    onSuccess?: (() => void) | undefined;
  }) => Promise<void>;

  logoutApp: ({
    onSuccess,
  }: {
    onSuccess?: (() => void) | undefined;
  }) => Promise<void>;

  openSessionExpiredDialog: () => void;
};

export const addAuthCustomEventListeners = ({
  initiateApp,
  logoutApp,
  openSessionExpiredDialog,
}: AuthCustomEventListeners) => {
  window.addEventListener(AppEvents.LOGIN_SUCCESS, ((e: CustomEvent) => {
    initiateApp({ type: "SIGN_IN", onSuccess: e.detail?.onSuccess });
  }) as EventListener);

  window.addEventListener(AppEvents.LOGGED_OUT, ((e: CustomEvent) => {
    logoutApp({ onSuccess: e.detail?.onSuccess });
  }) as EventListener);

  window.addEventListener(AppEvents.APP_RELOAD, ((e: CustomEvent) => {
    initiateApp({ type: "RELOAD", onSuccess: e.detail?.onSuccess });
  }) as EventListener);

  window.addEventListener(AppEvents.NOT_AUTHENTICATED, () => {
    openSessionExpiredDialog();
  });
};
