import { User, UserProfile } from "types/User";
import { apiSlice } from "./api.slice";

const userSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getUser: builder.mutation<
      { user: User; profile: UserProfile },
      { skipExpiredSessionCheck: boolean }
    >({
      query: (data) => ({
        url: "/users/me",
        method: "GET",
        config: {
          skipExpiredSessionCheck: data.skipExpiredSessionCheck,
        },
      }),
    }),
  }),
});

export const { useGetUserMutation } = userSlice;
