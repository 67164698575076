import dayjs from "dayjs";
import { Paper } from "@mui/material";
import { LineChart } from "@mui/x-charts/LineChart";
import { selectSelectedBusinessCurrency } from "app/app.slice";
import { getTotalPaymentSeriesData } from "app/modules/Dashboard/MonthlyCollectionChart/helpers";
import { useAppSelector } from "lib/store/hooks";
import { useGetPaymentMetricsQuery } from "services/payments.slice";

export const MonthlyCollectionChart = () => {
  const currency = useAppSelector(selectSelectedBusinessCurrency);

  const {
    data: paymentMetrics,
    isLoading: isLoadingPaymentMetrics,
    isFetching: isFetchingPaymentMetrics,
  } = useGetPaymentMetricsQuery();

  const totalPaymentsPerMonthSeriesData = getTotalPaymentSeriesData(
    paymentMetrics?.totalPaymentsPerMonth || {}
  );

  return (
    <Paper
      sx={{
        width: "100%",
        height: "100%",
        minWidth: 300,
        minHeight: 300,
        paddingLeft: 2,
        paddingBottom: 2,
      }}
    >
      <LineChart
        loading={isLoadingPaymentMetrics || isFetchingPaymentMetrics}
        xAxis={[
          {
            id: "Months",
            data: [5, 4, 3, 2, 1, 0],
            scaleType: "point",
            valueFormatter: (i) =>
              dayjs().subtract(i, "month").startOf("month").format("MMM 'YY"),
          },
        ]}
        series={[
          {
            id: "Amount",
            label: "Monthly Collection",
            data: totalPaymentsPerMonthSeriesData,
            valueFormatter: (value) =>
              value ? `${currency} ${value}` : "Not Available",
          },
        ]}
      />
    </Paper>
  );
};
