import React, { useState } from "react";
import { CustomerWithMemberAndMemberDetails } from "services/types/member.types";
import { Button } from "@mui/material";
import { AddMembershipForm } from "app/modules/Members/AddMembership/AddMembershipForm";

export const AddMembershipAction = ({
  selectedCustomer,
}: {
  selectedCustomer: CustomerWithMemberAndMemberDetails;
}) => {
  const [isMembershipDialogOpen, setIsMembershipDialogOpen] = useState(false);

  return (
    <React.Fragment>
      <Button
        type="submit"
        variant="contained"
        size="large"
        color="primary"
        onClick={() => setIsMembershipDialogOpen(true)}
      >
        Add Membership
      </Button>

      <AddMembershipForm
        open={isMembershipDialogOpen}
        selectedCustomer={selectedCustomer}
        onClose={() => setIsMembershipDialogOpen(false)}
        onSuccess={() => setIsMembershipDialogOpen(false)}
        // TODO: Refresh the subscription list after membership is created
      />
    </React.Fragment>
  );
};
