import { Box, CircularProgress } from "@mui/material";
import { AcceptInvite } from "app/views/BusinessNotification/AcceptInvite";
import { CreateBusinessNotification } from "app/views/BusinessNotification/CreateBusinessNotification";
import { useGetMyInvitesQuery } from "services/team.slice";

export const BusinessNotification = () => {
  const { data, isLoading } = useGetMyInvitesQuery();

  if (isLoading) {
    return (
      <Box p={4} display="flex" justifyContent="center" alignItems="center">
        <CircularProgress size={20} />
      </Box>
    );
  }

  const invitations = data?.invitations;

  //TODO: handle multiple invitations
  if (invitations && invitations.length > 0) {
    return <AcceptInvite invitation={invitations[0]} />;
  }

  return <CreateBusinessNotification />;
};
