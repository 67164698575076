import { LoginPayload, RegisterPayload } from "services/types";
import { User } from "types/User";
import { apiSlice } from "./api.slice";

const authSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation<any, LoginPayload>({
      query: (body) => ({
        url: "/auth/signin",
        method: "POST",
        data: body,
        config: {
          skipExpiredSessionCheck: true,
        },
      }),
    }),
    register: builder.mutation<{ user: User }, RegisterPayload>({
      query: (body) => ({
        url: "/auth/signup",
        method: "POST",
        data: body,
        config: {
          skipExpiredSessionCheck: true,
        },
      }),
    }),
    logout: builder.mutation<{ message: string }, null>({
      query: () => ({
        url: "/auth/logout",
        method: "GET",
        config: {
          skipExpiredSessionCheck: true,
        },
      }),
    }),
  }),
});

export const { useLoginMutation, useRegisterMutation, useLogoutMutation } =
  authSlice;
