import { Navigate, Outlet } from "react-router-dom";
import { Paper, styled } from "@mui/material";
import { useShouldNavigate } from "app/Routes/useShouldNavigate";
import { Paths } from "data";
import gymImage from "./image.jpeg";

export const AuthLayoutWrapper = styled("div")(({ theme }) => ({
  backgroundColor: "red",
  width: "100%",
  height: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: 20,

  background: `linear-gradient( to bottom, rgba(0, 0, 0, 0.8),  rgba(0, 0, 0, 0.8) ), url(${gymImage})`,
  backgroundSize: "cover",
  backgroundPosition: "center",
}));

export const AuthLayout = () => {
  const { shouldNavigateToCreateBusiness, shouldNavigateToDashboard } =
    useShouldNavigate();

  if (shouldNavigateToCreateBusiness) {
    return (
      <Navigate
        to={Paths.BUSINESS.BASE + Paths.BUSINESS.NOTIFICATION}
        replace
      />
    );
  }

  if (shouldNavigateToDashboard) {
    return <Navigate to={Paths.DASHBOARD.BASE} replace />;
  }

  return (
    <AuthLayoutWrapper>
      <Paper elevation={24} sx={{ width: { xs: "100%", sm: "500px" } }}>
        <Outlet />
      </Paper>
    </AuthLayoutWrapper>
  );
};
