import { selectUser } from "app/app.slice";
import { useFormik } from "formik";
import { useAppSnackbar } from "lib/contexts/SnackbarContext";
import { useAppSelector } from "lib/store/hooks";
import { isEmail } from "lib/utils/validators/isEmail";

const validate = (values: {
  email: string;
  name: string;
  phoneNumber: string;
  role: string;
}) => {
  const errors: {
    email?: string;
    name?: string;
    phoneNumber?: string;
    role?: string;
  } = {};

  if (!values.email) {
    errors.email = "Email is required";
  } else if (!isEmail(values.email)) {
    errors.email = "Invalid email address";
  }

  if (!values.name) {
    errors.name = "Name is required";
  }

  if (!values.phoneNumber) {
    errors.phoneNumber = "Phone number is required";
  }

  if (!values.role) {
    errors.role = "Role is required";
  }

  return errors;
};

export const useProfileForm = () => {
  const snackbar = useAppSnackbar();

  const user = useAppSelector(selectUser);

  const formik = useFormik({
    initialValues: {
      email: user.email,
      name: user.profile.name,
      phoneNumber: user.phone || "",
      role: user.businessRole || "",
    },
    validate,
    onSubmit: async (values) => {
      snackbar.show("Profile Updated Successfully!", {
        variant: "success",
        preventDuplicate: true,
      });
    },
  });

  return { formik };
};
