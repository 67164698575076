import { Box, Grid } from "@mui/material";
import { MembershipExpiringCard } from "app/modules/Dashboard/MembershipExpiringCard";
import { MetricCards } from "app/modules/Dashboard/MetricCards";
import { MonthlyCollectionChart } from "app/modules/Dashboard/MonthlyCollectionChart/MonthlyCollectionChart";
import { AppPermission } from "lib/constants";
import { Can } from "ui/components/Can/Can";

export const Dashboard = () => {
  return (
    <Box padding={{ xs: 2, sm: 3 }}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={7}>
          <MetricCards />
        </Grid>
        <Can I={[AppPermission.ManageCustomerSubscriptions]}>
          <Grid item xs={12} md={5}>
            <MonthlyCollectionChart />
          </Grid>
        </Can>
        <Can I={[AppPermission.ViewCustomers]}>
          <Grid item xs={12}>
            <MembershipExpiringCard />
          </Grid>
        </Can>
      </Grid>
    </Box>
  );
};
