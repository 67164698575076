import { AppEvents } from "data";
import { useFormik } from "formik";
import { useAppSnackbar } from "lib/contexts/SnackbarContext";
import { isPhoneNumber } from "lib/utils/validators/isPhoneNumber";
import { useLoginMutation } from "services/auth.slice";

const validate = (values: { phone: string; password: string }) => {
  const errors: { phone?: string; password?: string } = {};

  if (!values.phone) {
    errors.phone = "Mobile Number is required";
  } else if (!isPhoneNumber(values.phone)) {
    errors.phone = "Invalid Mobile Number";
  }

  if (!values.password) {
    errors.password = "Password is required";
  }

  return errors;
};

export const useLoginForm = () => {
  const [login] = useLoginMutation();

  const snackbar = useAppSnackbar();

  const formik = useFormik({
    initialValues: {
      phone: "",
      password: "",
    },
    validate,
    onSubmit: async (values) => {
      const response = await login({
        phone: values.phone,
        password: values.password,
      });

      if ("data" in response) {
        window.dispatchEvent(new CustomEvent(AppEvents.LOGIN_SUCCESS));
      } else if ("error" in response) {
        snackbar.show(response.error as string, {
          variant: "error",
          preventDuplicate: true,
        });
      }
    },
  });

  return { formik };
};
