import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { Box, Button, Typography, useTheme } from "@mui/material";
import { CloudUpload as CloudUploadIcon } from "@mui/icons-material";

interface DragAndDropProps {
  onDrop: (acceptedFiles: File[]) => void;
  size?: "small" | "medium" | "large";
}

export const DragAndDrop: React.FC<DragAndDropProps> = ({
  onDrop,
  size = "large",
}) => {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === "dark";

  const handleDrop = useCallback(
    (acceptedFiles: File[]) => {
      onDrop(acceptedFiles);
    },
    [onDrop]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: handleDrop,
  });

  const width = size === "small" ? 300 : size === "medium" ? 500 : "100%";
  const height = size === "small" ? 100 : size === "medium" ? 200 : 600;

  return (
    <Box
      {...getRootProps()}
      sx={{
        p: 2,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: theme.shape.borderRadius,
        width,
        height,
        minHeight: 200,
        minWidth: 200,
        maxWidth: 1200,
        border: `2px dashed ${
          isDarkMode ? theme.palette.grey[700] : theme.palette.grey[300]
        }`,
        transition: "background-color 0.2s ease",
        cursor: "pointer",
        ":hover": {
          backgroundColor: isDarkMode
            ? theme.palette.grey[800]
            : theme.palette.grey[100],
        },
      }}
    >
      <input {...getInputProps()} />
      <Box display="flex" flexDirection="column" alignItems="center">
        <CloudUploadIcon fontSize="large" color="primary" />
        <Typography variant="h6">
          {size !== "small"
            ? isDragActive
              ? "Drop the files here..."
              : "Drag & drop some files here, or click to select files"
            : ""}
        </Typography>
        <Button variant="contained" color="primary" sx={{ mt: 2 }}>
          Click to Upload
        </Button>
      </Box>
    </Box>
  );
};
