import { Alert } from "@mui/material";
import { SnackbarContent, CustomContentProps, useSnackbar } from "notistack";
import { forwardRef, useCallback } from "react";

interface SuccessSnackbarProps extends CustomContentProps {}

export const MuiSuccessSnackbar = forwardRef<
  HTMLDivElement,
  SuccessSnackbarProps
>((props, ref) => {
  const {
    // You have access to notistack props and options 👇🏼
    id,
    message,

    ...other
  } = props;

  const { closeSnackbar } = useSnackbar();

  const handleDismiss = useCallback(() => {
    closeSnackbar(id);
  }, [id, closeSnackbar]);

  return (
    <SnackbarContent ref={ref} role="alert" {...other}>
      <Alert
        onClose={handleDismiss}
        severity="success"
        variant="filled"
        sx={{ width: "100%" }}
      >
        {message}
      </Alert>
    </SnackbarContent>
  );
});
