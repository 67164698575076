import { Dayjs } from "dayjs";
import { useFormik } from "formik";
import { useAppSnackbar } from "lib/contexts/SnackbarContext";
import { useCreatePlanMutation } from "services/plan.slice";

const validate = (values: {
  name: string;
  description: string;
  minPrice: number | "";
  maxPrice: number | "";
  intervalCount: number | "";
  intervalUnit: "day" | "week" | "month" | "year";
  startDate: Dayjs | null;
  endDate: Dayjs | null;
  isRecurring: string;
  isRegistrationFeeRequired: string;
}) => {
  const errors: {
    name?: string;
    description?: string;
    minPrice?: string;
    maxPrice?: string;
    intervalCount?: string;
    intervalUnit?: string;
    startDate?: string;
    endDate?: string;
    isRecurring?: string;
    isRegistrationFeeRequired?: string;
  } = {};

  if (!values.name) {
    errors.name = "Required";
  }

  if (!values.minPrice) {
    errors.minPrice = "Required";
  }

  if (!values.maxPrice) {
    errors.maxPrice = "Required";
  }

  if (!values.intervalCount) {
    errors.intervalCount = "Required";
  }

  if (!values.intervalUnit) {
    errors.intervalUnit = "Required";
  }

  if (!values.startDate) {
    errors.startDate = "Required";
  }

  if (!values.endDate) {
    errors.endDate = "Required";
  }

  return errors;
};

export const useAddPlan = ({ onClose }: { onClose: () => void }) => {
  const [createPlan] = useCreatePlanMutation();

  const snackbar = useAppSnackbar();

  const formik = useFormik({
    initialValues: {
      name: "",
      description: "",
      minPrice: "",
      maxPrice: "",
      intervalCount: "",
      intervalUnit: "month",
      startDate: null,
      endDate: null,
      isRecurring: "0",
      isRegistrationFeeRequired: "1",
    },
    validate,
    onSubmit: async (values) => {
      const response = await createPlan({
        name: values.name,
        description: values.description,
        minPrice: values.minPrice as number, //TODO: check type once typecasting should not be needed
        maxPrice: values.maxPrice as number,
        intervalCount: values.intervalCount as number,
        intervalUnit: values.intervalUnit,
        startDate: values.startDate ? values.startDate.toISOString() : "",
        endDate: values.endDate ? values.endDate.toISOString() : "",
        isRecurring: Boolean(values.isRecurring),
        isRegistrationFeeRequired: Boolean(values.isRegistrationFeeRequired),
      });

      if ("data" in response) {
        snackbar.show("Plan created successfully", { variant: "success" });
        onClose();
      }

      if ("error" in response) {
        snackbar.show(response.error as string, {
          variant: "error",
          preventDuplicate: true,
        });
      }
    },
  });

  return {
    formik,
  };
};
