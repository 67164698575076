import { Box, Typography } from "@mui/material";

export const PageError = () => {
  return (
    <Box
      width="100%"
      height="100%"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Typography variant="h4" color="error">
        Something went wrong!
      </Typography>
    </Box>
  );
};
