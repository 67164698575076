import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import { DrawerHeight } from "data";

export const StyledDrawerWrapper = styled(Box)(({ theme }) => ({
  height: `${DrawerHeight}%`,
  overflowY: "auto",
}));

export const StyledAccountWrapper = styled(Box)(({ theme }) => ({
  height: `${100 - DrawerHeight}%`,
  padding: theme.spacing(2),

  "&:hover": {
    backgroundColor: theme.palette.action.hover,
  },
}));
