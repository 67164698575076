import { LoadingButton } from "@mui/lab";
import { Box, Paper, Typography } from "@mui/material";
import { updateSelectedBusiness } from "app/app.slice";
import { useAppSnackbar } from "lib/contexts/SnackbarContext";
import { useAcceptInviteMutation } from "services/team.slice";
import { InvitationWithBusiness } from "types/Invitation";
import { useAppDispatch } from "lib/store/hooks";

export const AcceptInvite = ({
  invitation,
}: {
  invitation: InvitationWithBusiness;
}) => {
  const dispatch = useAppDispatch();
  const snackbar = useAppSnackbar();

  const [acceptInvite, { isLoading }] = useAcceptInviteMutation();

  const handleAcceptInvite = async () => {
    try {
      const response = await acceptInvite({
        payload: {
          token: invitation.token,
        },
        setSelectedBusiness: 1,
      }).unwrap();

      if (response.business) {
        dispatch(
          updateSelectedBusiness({
            business: response.business,
          })
        );

        return;
      }
    } catch {
      snackbar.show("Invitation could not be accepted. Please try again.", {
        variant: "error",
      });
    }
  };

  const businessName = invitation.business.name;
  const role = invitation.role;

  return (
    <Box
      width="100%"
      height="100%"
      padding={2}
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Paper
        elevation={3}
        sx={{ padding: 4, width: { xs: "100%", sm: "500px" } }}
      >
        <Typography variant="h5" component="h1" fontWeight="bold" gutterBottom>
          Accept Invite
        </Typography>

        <Typography variant="body1" color="textSecondary" gutterBottom>
          Congratulations! You've been invited to join{" "}
          <Typography
            color="textPrimary"
            component="span"
            sx={{ fontWeight: 700 }}
            variant="body1"
          >
            {businessName}
          </Typography>{" "}
          as an{" "}
          <Typography
            color="textPrimary"
            component="span"
            sx={{ fontWeight: 700 }}
            variant="body1"
          >
            {role}
          </Typography>
          . Accept the invitation to kick-start your journey with us.
        </Typography>

        <LoadingButton
          variant="contained"
          size="large"
          sx={{ marginTop: 3 }}
          onClick={handleAcceptInvite}
          fullWidth
          loading={isLoading}
        >
          Accept
        </LoadingButton>
      </Paper>
    </Box>
  );
};
