import { Router } from "app/Routes";
import { AppLoadingStatus } from "data";
import { useInitiateApp } from "lib/hooks/useInitiateApp";
import { FullPageSpinner } from "ui/components/FullPageSpinner";

export const Main = () => {
  const { appLoadingStatus } = useInitiateApp();

  if (AppLoadingStatus.LOADING === appLoadingStatus) {
    return <FullPageSpinner />;
  }

  return <Router />;
};
