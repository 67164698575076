import { Box, Grid, Stack } from "@mui/material";
import { AddMemberAction } from "app/modules/Members/AddMember/AddMemberAction";
import { AllMembers } from "app/modules/Members/AllMembers/AllMembers";
import { BulkImportMemberAction } from "app/modules/Members/BulkImport/BulkImportAction";
import { ViewMemberDetails } from "app/modules/Members/ViewMember/ViewMember";
import { AppPermission } from "lib/constants";
import { useMemo, useState } from "react";
import { useGetAllCustomersQuery } from "services/customer.slice";
import { CustomerWithMemberAndMemberDetails } from "services/types/member.types";
import { Can } from "ui/components/Can/Can";
import { PageSpinner } from "ui/components/Loader/PageSpinner";
import { ModuleSearch } from "ui/components/ModuleSearch/ModuleSearch";

export const MembersView = () => {
  const { data: allCustomersData, isLoading: isAllCustomersLoading } =
    useGetAllCustomersQuery();

  const [selectedCustomer, setSelectedCustomer] =
    useState<CustomerWithMemberAndMemberDetails | null>(null);

  const customerListOptions = useMemo(() => {
    if (!allCustomersData) return [];

    return allCustomersData.map((customer) => ({
      label: customer.member.memberDetails.name,
      value: customer,
    }));
  }, [allCustomersData]);

  const handleCustomerSelect = (
    customer?: CustomerWithMemberAndMemberDetails
  ) => {
    if (customer) setSelectedCustomer(customer);
  };

  if (isAllCustomersLoading) {
    return <PageSpinner />;
  }

  return (
    <Box padding={{ xs: 2, sm: 3 }} width="100%" height="700px">
      <ViewMemberDetails
        selectedCustomer={selectedCustomer}
        setSelectedCustomer={() => setSelectedCustomer(null)}
      />

      <Stack direction="column" spacing={2}>
        <Grid container alignItems={"center"} gap={2}>
          <Grid item xs={12} md={6}>
            <ModuleSearch
              options={customerListOptions}
              searchLabel="Search"
              onChange={(data) => {
                handleCustomerSelect(data?.value);
              }}
            />
          </Grid>
          <Grid item xs={12} md={5}>
            <Stack direction="row" spacing={2}>
              <Can
                I={[AppPermission.ManageCustomers]}
                strict={false}
                variant="OVERLAY"
              >
                <AddMemberAction />
              </Can>
              <Can
                I={[AppPermission.ManageCustomers]}
                strict={false}
                variant="OVERLAY"
              >
                <BulkImportMemberAction />
              </Can>
            </Stack>
          </Grid>
        </Grid>
        <Can
          I={[AppPermission.ViewCustomers]}
          strict={false}
          variant="CONTAINER"
        >
          <AllMembers />
        </Can>
      </Stack>
    </Box>
  );
};
