import { useNavigate } from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home";
import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  useTheme,
} from "@mui/material";
import { Modules } from "app/modules/module-constants";
import { useCurrentModule } from "app/modules/useCurrentModule";
import { Can } from "ui/components/Can/Can";

export const HomeModule = ({
  handleDrawerClose,
}: {
  handleDrawerClose?: () => void;
}) => {
  const navigate = useNavigate();

  const theme = useTheme();

  const { module } = useCurrentModule();

  const isCurrentModule = module?.url === Modules.DASHBOARD.url;

  return (
    <Can I={module?.permissions ?? []} type="ANY">
      <ListItem disablePadding>
        <ListItemButton
          onClick={() => {
            navigate(Modules.DASHBOARD.url);
            handleDrawerClose?.();
          }}
          sx={{
            color: isCurrentModule ? theme.palette.primary.dark : "",
            backgroundColor: isCurrentModule
              ? theme.palette.action.selected
              : "",
          }}
        >
          <ListItemIcon sx={{ minWidth: "40px" }}>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText primary={Modules.DASHBOARD.title} />
        </ListItemButton>
      </ListItem>
    </Can>
  );
};
