import {
  Avatar,
  Box,
  Button,
  Grid,
  Paper,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import { selectUser } from "app/app.slice";
import { useProfileForm } from "app/views/UserProfile/useProfileForm";
import { useAppSelector } from "lib/store/hooks";
import { ActionableTitleContentCard } from "ui/atoms/ActionableTitleContentCard";

const AccountWrapper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  ...theme.typography.body2,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
}));

export const UserProfile = () => {
  const { formik } = useProfileForm();

  const user = useAppSelector(selectUser);

  return (
    <Box padding={{ xs: 2, sm: 3 }} width="100%">
      <Grid container spacing={3}>
        <Grid item xs={12} md={3}>
          <AccountWrapper elevation={3}>
            <Avatar
              sx={{ width: 150, height: 150, marginBottom: 2 }}
              src="https://i.pravatar.cc/500"
            >
              {user.profile.name}
            </Avatar>
            <Typography variant="h6">{user.profile.name}</Typography>
            <Typography variant="subtitle1" color="GrayText" gutterBottom>
              Admin
            </Typography>
          </AccountWrapper>
        </Grid>
        <Grid item xs={12} md={9}>
          <Box component="form" onSubmit={formik.handleSubmit}>
            <ActionableTitleContentCard>
              <ActionableTitleContentCard.Title>
                Your Profile
              </ActionableTitleContentCard.Title>
              <ActionableTitleContentCard.Content>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      name="name"
                      value={formik.values.name}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched.name && Boolean(formik.errors.name)}
                      helperText={formik.touched.name && formik.errors.name}
                      label="Name"
                      variant="outlined"
                      type="text"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      name="email"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.email && Boolean(formik.errors.email)
                      }
                      helperText={formik.touched.email && formik.errors.email}
                      label="Email"
                      variant="outlined"
                      type="email"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      name="phoneNumber"
                      value={formik.values.phoneNumber}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.phoneNumber &&
                        Boolean(formik.errors.phoneNumber)
                      }
                      helperText={
                        formik.touched.phoneNumber && formik.errors.phoneNumber
                      }
                      label="Phone Number"
                      variant="outlined"
                      type="number"
                      disabled
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      name="role"
                      value={formik.values.role}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched.role && Boolean(formik.errors.role)}
                      helperText={formik.touched.role && formik.errors.role}
                      label="Role"
                      variant="outlined"
                      fullWidth
                      disabled
                    />
                  </Grid>
                </Grid>
              </ActionableTitleContentCard.Content>
              <ActionableTitleContentCard.Actions>
                <>
                  <Button
                    type="button"
                    variant="outlined"
                    size="large"
                    color="secondary"
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    size="large"
                    color="primary"
                  >
                    Update
                  </Button>
                </>
              </ActionableTitleContentCard.Actions>
            </ActionableTitleContentCard>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
