export const Paths = {
  AUTH: {
    BASE: "/auth",
    LOGIN: "/login",
    REGISTER: "/register",
  },
  DASHBOARD: {
    BASE: "/",
  },
  MEMBERS: {
    BASE: "/members",
    ADD: "/add",
    BULK_IMPORT: "/bulk-import",
    VIEW: "/view",
    VIEW_ALL: "/all",
    ADD_MEMBERSHIP: "/add-membership",
    VIEW_MEMBERSHIP: "/view-membership",
  },
  PLANS: {
    BASE: "/plans",
    VIEW_ALL: "/all",
  },
  BILLING: {
    BASE: "/billing",
    RECORD_PAYMENT: "/record-payment",
    MEMBERSHIP_PAYMENTS: "/membership-payments",
  },
  MANAGE_TEAM: {
    BASE: "/manage-team",
    ADD: "/add",
    VIEW_ALL: "/all",
  },
  LEADS: {
    BASE: "/leads",
    VIEW_ALL: "/all",
  },
  USER_PROFILE: "/user-profile",
  SETTINGS: "/settings",
  BUSINESS: {
    BASE: "/business",
    NOTIFICATION: "/notification",
  },
} as const;

export const AppLoadingStatus = {
  IDLE: "IDLE",
  LOADING: "LOADING",
  SUCCESS: "SUCCESS",
  ERROR: "ERROR",
} as const;

export const AppEvents = {
  NOT_AUTHENTICATED: "notAuthenticated",
  LOGGED_OUT: "loggedOut",
  LOGIN_SUCCESS: "loginSuccess",
  APP_RELOAD: "appReload",
};

export const ErrorMessages = {
  GENERIC: "Uh Oh! Something went wrong.",
  NETWORK_ERROR:
    "Oops! There was problem in contacting our servers. Please try again later",
};

export const DrawerWidth = 250;
export const DrawerHeight = 90;
export const AppBarHeight = 64;
