import { Box } from "@mui/material";
import { useShouldNavigate } from "app/Routes/useShouldNavigate";
import { AppBarHeight, Paths } from "data";
import { Navigate, Outlet } from "react-router-dom";
import { FullPageAppBar } from "ui/components/FullPageAppBar";

export const FullPageLayout = () => {
  const { shouldNavigateToLogin, shouldNavigateToDashboard } =
    useShouldNavigate();

  if (shouldNavigateToLogin) {
    return <Navigate to={Paths.AUTH.BASE + Paths.AUTH.LOGIN} replace />;
  }

  if (shouldNavigateToDashboard) {
    return <Navigate to={Paths.DASHBOARD.BASE} replace />;
  }

  return (
    <Box height="100%" width="100%">
      <FullPageAppBar />
      <Box component="main" width="100%" height="100%">
        {/* 64px is the height of the app bar */}
        {/* TODO: figure better way to do this */}
        <Box height={`${AppBarHeight}px`} width="100%" />
        <Box width="100%" sx={{ height: `calc(100% - ${AppBarHeight}px)` }}>
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
};
