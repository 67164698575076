import {
  Avatar,
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useCreateBusinessForm } from "app/views/CreateBusiness/useCreateBusinessForm";
import { ActionableTitleContentCard } from "ui/atoms/ActionableTitleContentCard";

export const CreateBusinessForm = () => {
  const { formik } = useCreateBusinessForm();

  return (
    <Box
      width="100%"
      padding={8}
      display="flex"
      justifyContent="center"
      alignItems="center"
      overflow="auto"
    >
      <Box
        component="form"
        onSubmit={formik.handleSubmit}
        height="fit-content"
        width={{ xs: "100%", md: "800px" }}
      >
        <ActionableTitleContentCard>
          <ActionableTitleContentCard.Title>
            Create Business
          </ActionableTitleContentCard.Title>
          <ActionableTitleContentCard.Content>
            <Grid container spacing={2}>
              <Box width="100%" display="flex" justifyContent="center">
                <Avatar
                  sx={{ width: 150, height: 150, marginBottom: 2 }}
                  src="https://i.pravatar.cc/500"
                >
                  Business Logo
                </Avatar>
              </Box>
              <Grid item xs={12} md={6}>
                <TextField
                  name="businessName"
                  value={formik.values.businessName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.businessName &&
                    Boolean(formik.errors.businessName)
                  }
                  helperText={
                    formik.touched.businessName && formik.errors.businessName
                  }
                  label="Business Name"
                  variant="outlined"
                  type="text"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  name="tagline"
                  value={formik.values.tagline}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.tagline && Boolean(formik.errors.tagline)
                  }
                  helperText={formik.touched.tagline && formik.errors.tagline}
                  label="Tagline"
                  variant="outlined"
                  type="text"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  name="phone"
                  value={formik.values.phone}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.phone && Boolean(formik.errors.phone)}
                  helperText={formik.touched.phone && formik.errors.phone}
                  label="Phone Number"
                  variant="outlined"
                  type="text"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  name="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                  label="Email"
                  variant="outlined"
                  type="email"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  name="address"
                  value={formik.values.address}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.address && Boolean(formik.errors.address)
                  }
                  helperText={formik.touched.address && formik.errors.address}
                  label="Address"
                  variant="outlined"
                  type="text"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  name="city"
                  value={formik.values.city}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.city && Boolean(formik.errors.city)}
                  helperText={formik.touched.city && formik.errors.city}
                  label="City"
                  variant="outlined"
                  type="text"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  name="state"
                  value={formik.values.state}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.state && Boolean(formik.errors.state)}
                  helperText={formik.touched.state && formik.errors.state}
                  label="State"
                  variant="outlined"
                  type="text"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  name="country"
                  value={formik.values.country}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.country && Boolean(formik.errors.country)
                  }
                  helperText={formik.touched.country && formik.errors.country}
                  label="Country"
                  variant="outlined"
                  type="text"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  name="zipcode"
                  value={formik.values.zipcode}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.zipcode && Boolean(formik.errors.zipcode)
                  }
                  helperText={formik.touched.zipcode && formik.errors.zipcode}
                  label="Pincode"
                  variant="outlined"
                  type="text"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl
                  fullWidth
                  error={!!formik.touched.currency && !!formik.errors.currency}
                >
                  <InputLabel>Currency</InputLabel>
                  <Select
                    name="currency"
                    label="Currency"
                    value={formik.values.currency}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.currency && Boolean(formik.errors.currency)
                    }
                  >
                    <MenuItem value="INR">INR</MenuItem>
                    <MenuItem value="USD">USD</MenuItem>
                  </Select>
                  <FormHelperText>
                    {formik.touched.currency && formik.errors.currency}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  name="registrationFee"
                  value={formik.values.registrationFee}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.registrationFee &&
                    Boolean(formik.errors.registrationFee)
                  }
                  helperText={
                    formik.touched.registrationFee &&
                    formik.errors.registrationFee
                  }
                  label="Registration Fee"
                  variant="outlined"
                  type="number"
                  fullWidth
                />
              </Grid>
            </Grid>
          </ActionableTitleContentCard.Content>
          <ActionableTitleContentCard.Actions>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              size="large"
              fullWidth
            >
              Create Business
            </Button>
          </ActionableTitleContentCard.Actions>
        </ActionableTitleContentCard>
      </Box>
    </Box>
  );
};
