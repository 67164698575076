import { Box } from "@mui/material";
import { AddPlanAction } from "app/modules/Plans/AddPlan/AddPlanAction";
import { AllPlans } from "app/modules/Plans/AllPlans/AllPlans";
import { AppPermission } from "lib/constants";
import { Can } from "ui/components/Can/Can";

export const PlansView = () => {
  return (
    <Box width="100%" height="100%" position="relative">
      <Can I={[AppPermission.CreateAndUpdatePlans]}>
        <AddPlanAction />
      </Can>
      <Can I={[AppPermission.ViewPlans]} strict={false}>
        <AllPlans />
      </Can>
    </Box>
  );
};
