import { combineReducers } from "@reduxjs/toolkit";
import appReducer from "app/app.slice";
import { RESET_STATE } from "lib/store/constants";
import { apiSlice } from "services/api.slice";
import { UnknownAction } from "@reduxjs/toolkit";

const combinedAppReducers = combineReducers({
  app: appReducer,
  [apiSlice.reducerPath]: apiSlice.reducer,
});

export const rootReducer = (state: any, action: UnknownAction) => {
  if (action.type === RESET_STATE) {
    return combinedAppReducers(undefined, action);
  }
  return combinedAppReducers(state, action);
};
