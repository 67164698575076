import { Box } from "@mui/material";
import { DataGrid, GridColDef, GridRowsProp } from "@mui/x-data-grid";
import { SelectedMemberDetails } from "app/modules/Members/BulkImport/types";
import { MemberDetails } from "types/Member";

const getTableRows = (data?: SelectedMemberDetails[]): GridRowsProp => {
  if (!data) return [];

  return data.map((details: SelectedMemberDetails) => ({
    id: details.id,
    name: details.name,
    gender: details.gender,
    contact: details.phone,
    email: details.email,
  }));
};

const columns: GridColDef[] = [
  { field: "id", headerName: "ID", width: 50 },
  { field: "name", headerName: "Name", width: 250 },
  { field: "gender", headerName: "Gender", width: 150 },
  { field: "contact", headerName: "Contact", width: 200 },
  { field: "email", headerName: "Email", width: 300 },
];

const CustomNoRowsOverlay = () => {
  return (
    <Box
      width="100%"
      height="100%"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      No Rows Found
    </Box>
  );
};

export const MembersList = ({ data }: { data: SelectedMemberDetails[] }) => {
  const rows = getTableRows(data);

  return (
    <Box width="100%" height="600px">
      <DataGrid
        rows={rows}
        columns={columns}
        slots={{ noRowsOverlay: CustomNoRowsOverlay }}
        checkboxSelection
      />
    </Box>
  );
};
