import { TagTypesMap } from "services/constants";
import {
  AcceptInviteRequest,
  GetAllInvitesResponse,
  GetMyInvitesResponse,
  GetTeamMembersResponse,
  GetTeamRolesAndPermissionsResponse,
  SendInviteRequest,
} from "services/types/team.types";
import { Business } from "types/Business";
import { apiSlice } from "./api.slice";

const teamSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getRolesAndPermissions: builder.query<
      GetTeamRolesAndPermissionsResponse,
      void
    >({
      query: () => ({
        url: "/team/roles-and-permissions",
        method: "GET",
      }),
      providesTags: [TagTypesMap.Team],
    }),

    getTeamMembers: builder.query<GetTeamMembersResponse, void>({
      query: () => ({
        url: "/team/members",
        method: "GET",
      }),
    }),

    getAllInvites: builder.query<GetAllInvitesResponse, void>({
      query: () => ({
        url: "/team/invites",
        method: "GET",
      }),
      providesTags: [TagTypesMap.Team],
    }),

    getMyInvites: builder.query<GetMyInvitesResponse, void>({
      query: () => ({
        url: "/team/my-invites",
        method: "GET",
      }),
      providesTags: [TagTypesMap.Team],
    }),

    sendInvite: builder.mutation<void, SendInviteRequest>({
      query: ({ email, role }) => ({
        url: "/team/invite",
        method: "POST",
        data: { email, role },
      }),
      invalidatesTags: [TagTypesMap.Team],
    }),

    deleteInvite: builder.mutation<{ message: string }, { id: number }>({
      query: ({ id }) => ({
        url: `/team/invite/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: [TagTypesMap.Team],
    }),

    acceptInvite: builder.mutation<
      { business: Business },
      { payload: AcceptInviteRequest; setSelectedBusiness: 0 | 1 }
    >({
      query: ({ payload, setSelectedBusiness }) => ({
        url: "/team/accept-invite",
        method: "POST",
        params: {
          "set-selected-business": setSelectedBusiness,
        },
        data: { ...payload },
      }),
      invalidatesTags: [TagTypesMap.Team, TagTypesMap.Business],
    }),
  }),
});

export const {
  useGetRolesAndPermissionsQuery,
  useLazyGetRolesAndPermissionsQuery,
  useGetMyInvitesQuery,
  useGetTeamMembersQuery,
  useGetAllInvitesQuery,
  useSendInviteMutation,
  useAcceptInviteMutation,
  useDeleteInviteMutation,
} = teamSlice;
