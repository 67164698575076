import { Paths } from "data";
import { Dayjs } from "dayjs";
import { useFormik } from "formik";
import { useAppSnackbar } from "lib/contexts/SnackbarContext";
import { isPhoneNumber } from "lib/utils/validators/isPhoneNumber";
import { useNavigate } from "react-router-dom";
import { useCreateCustomerMutation } from "services/customer.slice";

const validate = (values: {
  name: string;
  email: string;
  phone: string;
  gender: "male" | "female" | "other" | "";
  dob: Dayjs | null;
  address: string;
}) => {
  const errors: {
    name?: string;
    email?: string;
    phone?: string;
    gender?: string;
    dob?: string;
    address?: string;
  } = {};

  if (!values.phone) {
    errors.phone = "Phone Number is required";
  } else if (!isPhoneNumber(values.phone)) {
    errors.phone = "Invalid Mobile Number";
  }

  if (!values.name) {
    errors.name = "Name is required";
  }

  if (!values.email) {
    errors.email = "Email is required";
  }

  if (!values.gender) {
    errors.gender = "Gender is required";
  }

  if (!values.dob) {
    errors.dob = "Date of Birth is required";
  }

  if (!values.address) {
    errors.address = "Address is required";
  }

  return errors;
};

export const useAddMemberForm = ({ onSuccess }: { onSuccess: () => void }) => {
  const [createCustomer] = useCreateCustomerMutation();

  const snackbar = useAppSnackbar();

  const formik = useFormik({
    initialValues: {
      email: "",
      name: "",
      phone: "",
      gender: "",
      dob: null,
      address: "",
    },
    validate,
    onSubmit: async (values) => {
      const response = await createCustomer({
        email: values.email,
        name: values.name,
        phone: values.phone,
        address: values.address,
        gender: values.gender,
        dob: values.dob ? values.dob?.toISOString() : "",
      });

      if ("data" in response) {
        snackbar.show("Member added successfully", { variant: "success" });
        onSuccess();
      }

      if ("error" in response) {
        snackbar.show(response.error as string, {
          variant: "error",
          preventDuplicate: true,
        });
      }
    },
  });

  return {
    formik,
  };
};
