import { BillingModule } from "app/modules/Billing/billing.module";
import { HomeModule } from "app/modules/Dashboard/dashboard.module";
import { LeadsModule } from "app/modules/Leads/leads.module";
import { MemberModule } from "app/modules/Members/members.module";
import { PlansModule } from "app/modules/Plans/plans.module";
import { TeamManagementModule } from "app/modules/TeamManagement/teamManagement.module";
import { ModulesType } from "app/modules/types";
import { Paths } from "data";
import { AppPermission } from "lib/constants";

export const Modules: ModulesType = {
  DASHBOARD: {
    title: "Dashboard",
    url: Paths.DASHBOARD.BASE,
    permissions: [
      AppPermission.ViewCustomers,
      AppPermission.ManageCustomerSubscriptions,
      AppPermission.ViewCustomerSubscriptions,
    ],
  },
  MEMBERS: {
    title: "Members",
    url: Paths.MEMBERS.BASE + Paths.MEMBERS.VIEW_ALL,
    permissions: [
      AppPermission.ManageCustomers,
      AppPermission.ViewCustomers,
      AppPermission.ManageCustomerSubscriptions,
      AppPermission.ViewCustomerSubscriptions,
    ],
  },
  PLANS: {
    title: "Plans",
    url: Paths.PLANS.BASE + Paths.PLANS.VIEW_ALL,
    permissions: [
      AppPermission.ViewPlans,
      AppPermission.CreateAndManageBusiness,
    ],
  },
  BILLING: {
    title: "Billing",
    url: Paths.BILLING.BASE,
    permissions: [AppPermission.ViewCustomerSubscriptions],
    subModules: {
      ADD: {
        title: "Payment History",
        url: Paths.BILLING.BASE + Paths.BILLING.MEMBERSHIP_PAYMENTS,
        permissions: [AppPermission.ViewCustomerSubscriptions],
      },
    },
  },
  MANAGE_TEAM: {
    title: "Manage Team",
    url: Paths.MANAGE_TEAM.BASE,
    permissions: [
      AppPermission.ViewTeamMembers,
      AppPermission.ManageTeamMembers,
    ],
    subModules: {
      ADD: {
        title: "Invite",
        url: Paths.MANAGE_TEAM.BASE + Paths.MANAGE_TEAM.ADD,
        permissions: [AppPermission.ManageTeamMembers],
      },
      VIEW: {
        title: "View Team",
        url: Paths.MANAGE_TEAM.BASE + Paths.MANAGE_TEAM.VIEW_ALL,
        permissions: [AppPermission.ViewTeamMembers],
      },
    },
  },
  LEADS: {
    title: "Leads",
    url: Paths.LEADS.BASE + Paths.LEADS.VIEW_ALL,
    permissions: [AppPermission.CreateAndManageLeads, AppPermission.ViewLeads],
  },
  USER_PROFILE: {
    title: "User Profile",
    url: Paths.USER_PROFILE,
    permissions: [],
  },
  SETTINGS: {
    title: "Settings",
    url: Paths.SETTINGS,
    permissions: [],
  },
};

export const sidebarModulesList = [
  HomeModule,
  MemberModule,
  PlansModule,
  BillingModule,
  TeamManagementModule,
  LeadsModule,
];
