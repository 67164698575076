import {
  createContext,
  useCallback,
  useContext,
  useRef,
  useState,
} from "react";

export type DialogContextType = {
  isOpen: boolean;
  openDialog: ({ dialog }: { dialog: JSX.Element }) => void;
  closeDialog: () => void;
};

const DialogContext = createContext<DialogContextType>({
  isOpen: false,
  openDialog: () => {},
  closeDialog: () => {},
});

export const DialogProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const dialogRef = useRef<JSX.Element | null>(null);

  const [open, setOpen] = useState<boolean>(false);

  const openDialog = useCallback(({ dialog }: { dialog: JSX.Element }) => {
    console.log("openDialog called", dialogRef.current, dialog);
    dialogRef.current = dialog;
    setOpen(true);
  }, []);

  const closeDialog = useCallback(() => {
    dialogRef.current = null;
    setOpen(false);
  }, []);

  return (
    <DialogContext.Provider value={{ isOpen: open, openDialog, closeDialog }}>
      {children}
      {open && dialogRef.current}
    </DialogContext.Provider>
  );
};

/**
 * @deprecated don't use this hook, this will not work
 */
export const useDialog = () => {
  if (!DialogContext) {
    throw new Error("useDialog must be used within a DialogProvider");
  }

  return useContext(DialogContext);
};
