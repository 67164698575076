import { createTheme } from "@mui/material/styles";
import { PaletteMode } from "@mui/material";
import type {} from "@mui/x-date-pickers/themeAugmentation";
import type {} from '@mui/x-data-grid/themeAugmentation';

//TODO: Update the theme configuration
export const createAppTheme = ({ mode }: { mode: PaletteMode }) =>
  createTheme({
    palette: {
      mode,
    },
  });
