import { Business, Businesses } from "types/Business";
import { apiSlice } from "./api.slice";

const businessSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllBusinesses: builder.mutation<
      { businesses: Businesses; selectedBusinessId: number },
      {
        skipExpiredSessionCheck: boolean;
      }
    >({
      query: (data) => ({
        url: "/business",
        method: "GET",
        config: {
          skipExpiredSessionCheck: data.skipExpiredSessionCheck,
        },
      }),
    }),
    createBusiness: builder.mutation<
      { business: Business },
      {
        name: string;
        tagline: string;
        phone: string;
        email: string;
        address: string;
        city: string;
        state: string;
        zipcode: string;
        country: string;
        config: {
          currency: string;
          registrationFee: number;
        };
      }
    >({
      query: (body) => ({
        url: "/business",
        method: "POST",
        data: body,
      }),
    }),

    switchBusiness: builder.mutation<
      { message: string },
      {
        id: Business["id"];
      }
    >({
      query: ({ id }) => ({
        url: `/business/switch/${id}`,
        method: "POST",
        data: undefined,
      }),
    }),
  }),
});

export const {
  useGetAllBusinessesMutation,
  useCreateBusinessMutation,
  useSwitchBusinessMutation,
} = businessSlice;
