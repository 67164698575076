import React, { useState } from "react";
import { Button } from "@mui/material";
import { AddMemberForm } from "app/modules/Members/AddMember/AddMemberForm";

export const AddMemberAction = () => {
  const [isAddMemberDialogOpen, setIsAddMemberDialogOpen] = useState(false);

  return (
    <React.Fragment>
      <Button
        type="submit"
        variant="outlined"
        size="large"
        color="primary"
        onClick={() => setIsAddMemberDialogOpen(true)}
      >
        Add Member
      </Button>

      <AddMemberForm
        open={isAddMemberDialogOpen}
        onClose={() => setIsAddMemberDialogOpen(false)}
      />
    </React.Fragment>
  );
};
