import { Member, MemberDetails } from "types/Member";
import {
  CustomerWithMemberAndMemberDetails,
  CustomerWithMemberAndMemberDetailsList,
} from "services/types/member.types";
import { TagTypesMap } from "services/constants";
import { Customer } from "types/Customer";
import {
  BulkUploadPayload,
  CustomerMetrics,
} from "services/types/customer.types";
import { apiSlice } from "./api.slice";

const customerSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllCustomers: builder.query<
      CustomerWithMemberAndMemberDetailsList,
      void
    >({
      query: () => ({
        url: "/customers",
        method: "GET",
      }),
      providesTags: [TagTypesMap.Customer],
    }),

    createCustomer: builder.mutation<
      CustomerWithMemberAndMemberDetails,
      {
        name: MemberDetails["name"];
        email: MemberDetails["email"];
        phone: MemberDetails["phone"];
        gender: MemberDetails["gender"];
        address: MemberDetails["address"];
        dob: MemberDetails["dob"];
      }
    >({
      query: (body) => ({
        url: "/customers",
        method: "POST",
        data: body,
      }),
      invalidatesTags: [TagTypesMap.Customer],
    }),

    updateCustomer: builder.mutation<
      CustomerWithMemberAndMemberDetails,
      {
        memberId: Member["id"];
        customerId: Customer["id"];
        name?: MemberDetails["name"];
        email?: MemberDetails["email"];
        phone?: MemberDetails["phone"];
        gender?: MemberDetails["gender"];
        address?: MemberDetails["address"];
        dob?: MemberDetails["dob"];
        pincode?: MemberDetails["zipcode"];
        city?: MemberDetails["city"];
        state?: MemberDetails["state"];
        nameOnInvoice?: Customer["nameOnInvoice"];
        rank?: Customer["rank"];
        active?: Member["active"];
      }
    >({
      query: (body) => ({
        url: `/customers/${body.customerId}`,
        method: "POST",
        data: {
          ...body,
          memberId: body.memberId,
          name: body.name,
          email: body.email,
          phone: body.phone,
          gender: body.gender,
          address: body.address,
          dob: body.dob,
          pincode: body.pincode,
          city: body.city,
          state: body.state,
          nameOnInvoice: body.nameOnInvoice,
          rank: body.rank,
          active: body.active,
        },
      }),
      invalidatesTags: [TagTypesMap.Customer],
    }),
    getCustomerMetrics: builder.query<CustomerMetrics, void>({
      query: () => ({
        url: `/customers/metrics`,
        method: "GET",
      }),
      providesTags: [TagTypesMap.Customer],
    }),
    bulkUploadCustomers: builder.mutation<
      { message: string },
      { customers: BulkUploadPayload[] }
    >({
      query: (body) => ({
        url: "/customers/bulk-upload",
        method: "POST",
        data: body,
      }),
      invalidatesTags: [TagTypesMap.Customer],
    }),
  }),
});

export const {
  useCreateCustomerMutation,
  useGetAllCustomersQuery,
  useUpdateCustomerMutation,
  useGetCustomerMetricsQuery,
  useBulkUploadCustomersMutation,
} = customerSlice;
