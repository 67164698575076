import { Description, Send } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Alert, Box, Grid, Paper, Stack, Typography } from "@mui/material";
import { selectSelectedBusinessCurrency } from "app/app.slice";
import { RecordPaymentAction } from "app/modules/Billing/RecordPayment/RecordPaymentAction";
import { format } from "date-fns";
import { useAppSelector } from "lib/store/hooks";
import {
  useCancelSubscriptionMutation,
  useSendInvoiceToCustomerMutation,
} from "services/subscription.slice";
import { CustomerWithMemberAndMemberDetails } from "services/types/member.types";
import { SubscriptionWithPlanDetails } from "types/Subscription";
import { Field } from "ui/atoms/Field";

export const MembershipDetails = ({
  selectedCustomer,
  customerSubscription,
}: {
  selectedCustomer: CustomerWithMemberAndMemberDetails;
  customerSubscription: SubscriptionWithPlanDetails;
}) => {
  const currency = useAppSelector(selectSelectedBusinessCurrency);

  const [cancelSubscription, { isLoading: isCanceling }] =
    useCancelSubscriptionMutation();
  const [sendInvoice, { isLoading: isSendingInvoice }] =
    useSendInvoiceToCustomerMutation();

  return (
    <Box width="100%">
      <Alert variant="filled" severity="info">
        Member already has an active membership
      </Alert>
      <Box height="20px" />
      <Paper elevation={3}>
        <Stack padding={2} direction="row" alignItems="center" spacing={1}>
          <Description />
          <Typography variant="h6">Active Membership Details</Typography>
        </Stack>
        <Grid
          container
          paddingX={3}
          paddingBottom={4}
          rowSpacing={4}
          columnSpacing={2}
        >
          <Grid item xs={12} md={4}>
            <Field label="Plan" value={customerSubscription.plan.name} />
          </Grid>
          <Grid item xs={12} md={4}>
            <Field
              label="Start Date"
              value={format(
                new Date(customerSubscription.startDate),
                "dd MMM yyyy"
              )}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Field
              label="End Date"
              value={format(
                new Date(customerSubscription.endDate),
                "dd MMM yyyy"
              )}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <Field
              label="Price"
              value={`${currency} ${customerSubscription.price}`}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <Field
              label="Paid Amount"
              value={`${currency} ${customerSubscription.amountPaid}`}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <Field
              label="Due Amount"
              value={`${currency} ${customerSubscription.dueAmount}`}
            />
          </Grid>
          <Grid item xs={12}>
            <Stack spacing={1} direction="row">
              <LoadingButton
                loading={isCanceling}
                variant="outlined"
                color="error"
                onClick={() => {
                  cancelSubscription({
                    customerSubscriptionId: customerSubscription.id,
                  });
                }}
              >
                Cancel
              </LoadingButton>

              <RecordPaymentAction
                selectedCustomer={selectedCustomer}
                selectedSubscription={customerSubscription}
              />

              <LoadingButton
                loading={isSendingInvoice}
                variant="outlined"
                color="primary"
                onClick={() => {
                  sendInvoice({
                    customerSubscriptionId: customerSubscription.id,
                  });
                }}
                endIcon={<Send />}
              >
                Invoice
              </LoadingButton>
            </Stack>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
};
