import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Collapse,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  useTheme,
} from "@mui/material";
import {
  HorizontalRule,
  ExpandLess,
  ExpandMore,
  Groups,
} from "@mui/icons-material";
import { Modules } from "app/modules/module-constants";
import { useCurrentModule } from "app/modules/useCurrentModule";
import { Can } from "ui/components/Can/Can";

export const TeamManagementModule = ({
  handleDrawerClose,
}: {
  handleDrawerClose?: () => void;
}) => {
  const [open, setOpen] = useState(false);

  const theme = useTheme();

  const navigate = useNavigate();

  const { module, subModule } = useCurrentModule();

  const isCurrentModule = module?.url === Modules.MANAGE_TEAM.url;

  const handleExpandToggle = () => {
    setOpen(!open);
  };

  return (
    <Can I={module?.permissions ?? []} type="ANY">
      <List sx={{ paddingY: 0 }}>
        <ListItem disablePadding>
          <ListItemButton
            onClick={handleExpandToggle}
            sx={{ color: isCurrentModule ? theme.palette.primary.dark : "" }}
          >
            <ListItemIcon sx={{ minWidth: "40px" }}>
              <Groups />
            </ListItemIcon>
            <ListItemText primary={Modules.MANAGE_TEAM.title} />
            {open ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
        </ListItem>

        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {Object.entries(Modules.MANAGE_TEAM.subModules || {}).map(
              ([key, value]) => (
                <Can I={value.permissions} key={key} type="ANY">
                  <ListItemButton
                    sx={{
                      pl: 3,
                      backgroundColor:
                        value.url === subModule?.url
                          ? theme.palette.action.selected
                          : "inherit",
                    }}
                    onClick={() => {
                      navigate(value.url);
                      handleDrawerClose?.();
                    }}
                  >
                    <ListItemIcon sx={{ minWidth: "40px" }}>
                      <HorizontalRule />
                    </ListItemIcon>
                    <ListItemText primary={value.title} />
                  </ListItemButton>
                </Can>
              )
            )}
          </List>
        </Collapse>
      </List>
    </Can>
  );
};
