import { selectBusinesses, selectSelectedBusinessId } from "app/app.slice";
import { useAppSelector } from "lib/store/hooks";

export const useGetBusiness = () => {
  const selectedBusinessId = useAppSelector(selectSelectedBusinessId);
  const businesses = useAppSelector(selectBusinesses);

  return {
    businesses,
    selectedBusiness: businesses.find(
      (business) => business.id === selectedBusinessId
    ),
  };
};
