import { useState } from "react";
import { Box } from "@mui/material";
import { Navigate, Outlet } from "react-router-dom";
import DashboardAppBar from "ui/components/DashboardAppBar/DashboardAppBar";
import { Sidebar } from "ui/components/Sidebar";
import { AppBarHeight, DrawerWidth, Paths } from "data";
import { useShouldNavigate } from "app/Routes/useShouldNavigate";

export const DashboardLayout = () => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [isClosing, setIsClosing] = useState(false);

  const { shouldNavigateToLogin, shouldNavigateToCreateBusiness } =
    useShouldNavigate();

  const handleDrawerClose = () => {
    setIsClosing(true);
    setMobileOpen(false);
  };

  const handleDrawerOpen = () => {
    if (!isClosing) {
      setMobileOpen(true);
    }
  };

  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };

  const handleDrawerToggle = () => {
    if (!isClosing) {
      setMobileOpen(!mobileOpen);
    }
  };

  if (shouldNavigateToLogin) {
    return <Navigate to={Paths.AUTH.BASE + Paths.AUTH.LOGIN} replace />;
  }

  if (shouldNavigateToCreateBusiness) {
    return (
      <Navigate
        to={Paths.BUSINESS.BASE + Paths.BUSINESS.NOTIFICATION}
        replace
      />
    );
  }

  return (
    <Box sx={{ display: "flex" }} width="100%" height="100%">
      <DashboardAppBar handleDrawerToggle={handleDrawerToggle} />
      <Sidebar
        handleDrawerOpen={handleDrawerOpen}
        handleDrawerClose={handleDrawerClose}
        handleDrawerTransitionEnd={handleDrawerTransitionEnd}
        mobileOpen={mobileOpen}
      />
      <Box
        component="main"
        sx={{
          height: "100%",
          width: { sm: `calc(100% - ${DrawerWidth}px)`, xs: "100%" },
        }}
      >
        {/* 64px is the height of the app bar */}
        {/* TODO: figure better way to do this */}
        <Box height={`${AppBarHeight}px`} width="100%" />
        <Box width="100%" sx={{ height: `calc(100% - ${AppBarHeight}px)` }}>
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
};
