import React, { useEffect } from "react";
import { Box, Skeleton, Toolbar, Typography } from "@mui/material";
import {
  getCustomerActiveSubscription,
  getCustomerInActiveSubscriptions,
} from "app/modules/Members/AddMembership/helpers";
import { MembershipListTable } from "app/modules/Members/ViewMembership/MembershipListTable";
import { useLazyGetSubscriptionsQuery } from "services/subscription.slice";
import { CustomerWithMemberAndMemberDetails } from "services/types/member.types";
import { AddMembershipAction } from "app/modules/Members/AddMembership/AddMembershipAction";
import { MembershipDetails } from "app/modules/Members/AddMembership/MembershipDetails";

export const ViewMembership = ({
  selectedCustomer,
}: {
  selectedCustomer: CustomerWithMemberAndMemberDetails | null;
}) => {
  const [
    getCustomerSubscriptions,
    {
      isLoading: isCustomerSubscriptionsLoading,
      data: customerSubscriptionsData,
    },
  ] = useLazyGetSubscriptionsQuery();

  useEffect(() => {
    if (selectedCustomer) {
      getCustomerSubscriptions({ customerId: selectedCustomer.id });
    }
  }, []);

  const customerActiveSubscription = getCustomerActiveSubscription(
    customerSubscriptionsData ?? []
  );

  const customerInActiveSubscriptionsList = getCustomerInActiveSubscriptions(
    customerSubscriptionsData || []
  );

  if (!selectedCustomer) return null;

  return (
    <React.Fragment>
      <Box>
        <Box>
          <Toolbar sx={{ padding: 0, paddingLeft: 0, paddingRight: 0 }}>
            <Typography sx={{ flex: 1 }} variant="h6" component="div">
              {selectedCustomer.member?.memberDetails?.name}'s Memberships
            </Typography>
            <AddMembershipAction selectedCustomer={selectedCustomer} />
          </Toolbar>
        </Box>

        <Box marginTop={3}>
          {customerActiveSubscription && (
            <MembershipDetails
              selectedCustomer={selectedCustomer}
              customerSubscription={customerActiveSubscription}
            />
          )}
        </Box>

        <Box marginTop={3}>
          <Toolbar>
            <Typography sx={{ flex: 1 }} variant="h6" component="div">
              Other Memberships
            </Typography>
          </Toolbar>

          {isCustomerSubscriptionsLoading ? (
            <Skeleton variant="rectangular" height="100px" />
          ) : (
            <MembershipListTable
              isCancelActionVisible={false}
              isDownloadInvoiceVisible={true}
              customerSubscriptionList={customerInActiveSubscriptionsList}
            />
          )}
        </Box>
      </Box>
    </React.Fragment>
  );
};
