export const getPathWithoutPrefixSlash = (pathname: string) => {
  return pathname.slice(1);
};

export const getShouldNavigateToLogin = ({
  isLoggedIn,
}: {
  isLoggedIn: boolean;
}) => {
  if (isLoggedIn) {
    return false;
  }

  return true;
};

export const getShouldNavigateToDashboard = ({
  isLoggedIn,
  selectedBusinessId,
}: {
  isLoggedIn: boolean;
  selectedBusinessId?: number;
}) => {
  if (isLoggedIn && !!selectedBusinessId) {
    return true;
  }

  return false;
};
