import { Box, Grid, Stack } from "@mui/material";
import { AppPermission } from "lib/constants";
import { useMemo, useState } from "react";
import { useGetAllLeadsQuery } from "services/leads.slice";
import { GetLeadsListResponse } from "services/types/lead.type";
import { Can } from "ui/components/Can/Can";

export const Leads = () => {
  const { data: allLeadsData, isLoading: isAllLeadsLoading } =
    useGetAllLeadsQuery();

  const [selectedLead, setSelectedLead] = useState<GetLeadsListResponse | null>(
    null
  );

  const leadListOptions = useMemo(() => {
    if (!allLeadsData) return [];

    return allLeadsData.map((customer) => ({
      label: customer.member.memberDetails.name,
      value: customer,
    }));
  }, [allLeadsData]);

  return (
    <Box padding={{ xs: 2, sm: 3 }} width="100%" height="700px">
      {/* <ViewMemberDetails
        selectedCustomer={selectedCustomer}
        setSelectedCustomer={() => setSelectedCustomer(null)}
      /> */}

      <Stack direction="column" spacing={2}>
        {/* <Grid container alignItems={"center"} gap={2}>
          <Grid item xs={12} md={6}>
            <ModuleSearch
              options={customerListOptions}
              searchLabel="Search"
              onChange={(data) => {
                handleCustomerSelect(data?.value);
              }}
            />
          </Grid>
          <Grid item xs={12} md={5}>
            <Stack direction="row" spacing={2}>
              <Can
                I={[AppPermission.ManageCustomers]}
                strict={false}
                variant="OVERLAY"
              >
                <AddMemberAction />
              </Can>
              <Can
                I={[AppPermission.ManageCustomers]}
                strict={false}
                variant="OVERLAY"
              >
                <BulkImportMemberAction />
              </Can>
            </Stack>
          </Grid>
        </Grid> */}
        <Can I={[AppPermission.ViewLeads]} strict={false} variant="CONTAINER">
          {/* <AllLeads /> */}
        </Can>
      </Stack>
    </Box>
  );
};
