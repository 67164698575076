import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Logout, Settings } from "@mui/icons-material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {
  Avatar,
  Box,
  Divider,
  ListItemIcon,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { AppEvents, Paths } from "data";
import { useAppSelector } from "lib/store/hooks";
import { selectUser } from "app/app.slice";
import { toTitleCase } from "lib/utils/toTitleCase";

export const UserAccount = ({
  handleDrawerClose,
}: {
  handleDrawerClose?: () => void;
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const navigate = useNavigate();

  const user = useAppSelector(selectUser);

  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleProfileClick = () => {
    navigate(Paths.USER_PROFILE);
    handleClose();
    handleDrawerClose?.();
  };

  const handleLogout = async () => {
    window.dispatchEvent(new CustomEvent(AppEvents.LOGGED_OUT));
    handleClose();
    handleDrawerClose?.();
  };

  const handleSettingsClick = () => {
    navigate(Paths.SETTINGS);
    handleClose();
    handleDrawerClose?.();
  };

  return (
    <React.Fragment>
      <Box
        display="flex"
        alignItems="center"
        gap={2}
        onClick={handleClick}
        tabIndex={0}
        sx={{ cursor: "pointer" }}
        aria-label="account of current user"
        aria-controls="primary-search-account-menu"
        aria-haspopup="true"
      >
        <Avatar
          sx={{ pointerEvents: "none" }}
          alt={user.profile.name}
          src="https://i.pravatar.cc/300"
        />
        <Box display="flex" flexDirection="column">
          <Typography
            variant="subtitle1"
            sx={{
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              overflow: "hidden",
              width: "100px",
            }}
          >
            {user.profile.name}
          </Typography>
          <Typography variant="subtitle2" color="GrayText">
            {toTitleCase(user.businessRole || "")}
          </Typography>
        </Box>
        <ChevronRightIcon />
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 25,
              height: 25,
              ml: -0.5,
              mr: 1,
            },
          },
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <MenuItem onClick={handleProfileClick}>
          <Avatar /> Profile
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleSettingsClick}>
          <ListItemIcon>
            <Settings fontSize="small" />
          </ListItemIcon>
          Settings
        </MenuItem>
        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
};
