import { useNavigate } from "react-router-dom";
import { Paths } from "data";
import { useFormik } from "formik";
import { useAppSnackbar } from "lib/contexts/SnackbarContext";
import { useSendInviteMutation } from "services/team.slice";

const validate = (values: { role: string; email: string }) => {
  const errors: {
    role?: string;
    email?: string;
  } = {};

  if (!values.email) {
    errors.email = "Email is required";
  }

  if (!values.role) {
    errors.role = "Role is required";
  }

  return errors;
};

export const useAddTeamMember = () => {
  const [sendInvite] = useSendInviteMutation();

  const snackbar = useAppSnackbar();
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      email: "",
      role: "",
    },
    validate,
    onSubmit: async (values) => {
      console.log(values);
      const response = await sendInvite({
        email: values.email,
        role: values.role,
      });

      if ("data" in response) {
        snackbar.show("User invited successfully", { variant: "success" });
        navigate(Paths.MANAGE_TEAM.BASE + Paths.MANAGE_TEAM.VIEW_ALL);
      }

      if ("error" in response) {
        snackbar.show(response.error as string, {
          variant: "error",
          preventDuplicate: true,
        });
      }
    },
  });

  return {
    formik,
  };
};
