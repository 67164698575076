import React, { useState } from "react";
import { Button } from "@mui/material";
import { BulkImportDialog } from "app/modules/Members/BulkImport/BulkImportDialog";

export const BulkImportMemberAction = () => {
  const [isBulkImportMemberDialogOpen, setIsBulkImportMemberDialogOpen] =
    useState(false);

  return (
    <React.Fragment>
      <Button
        type="submit"
        variant="outlined"
        size="large"
        color="primary"
        onClick={() => setIsBulkImportMemberDialogOpen(true)}
      >
        Bulk Import
      </Button>

      <BulkImportDialog
        open={isBulkImportMemberDialogOpen}
        onClose={() => setIsBulkImportMemberDialogOpen(false)}
      />
    </React.Fragment>
  );
};
