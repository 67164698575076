import {
  Chip,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import {
  StyledTableCell,
  StyledTableRow,
} from "app/modules/TeamManagement/ViewTeam/styles";
import { useGetTeamMembersQuery } from "services/team.slice";
import { Role } from "types/Team";
import { PageSpinner } from "ui/components/Loader/PageSpinner";
import { PageError } from "ui/components/PageError/PageError";

function createData({
  name,
  email,
  phone,
  role,
  isActive,
}: {
  name: string;
  email: string;
  phone: string;
  role: Role;
  isActive: boolean;
}) {
  return {
    name,
    email,
    phone,
    role,
    status: (
      <Chip
        size="small"
        label={isActive ? "Active" : "Inactive"}
        color={isActive ? "success" : "error"}
      />
    ),
  };
}

export const TeamMembers = () => {
  const { data, isLoading, isFetching } = useGetTeamMembersQuery();

  if (isLoading || isFetching) {
    return <PageSpinner />;
  }

  if (!data || !data.members) {
    return <PageError />;
  }

  const teamMembers = data.members;

  const rows = teamMembers.map((teamMember) => {
    return createData({
      name: teamMember.user.profile.name,
      email: teamMember.user.email,
      phone: teamMember.user.phone || "",
      role: teamMember.role || "",
      isActive: teamMember.active,
    });
  });

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="all memberships">
        <TableHead>
          <TableRow>
            <StyledTableCell>Name</StyledTableCell>
            <StyledTableCell align="center">Email</StyledTableCell>
            <StyledTableCell align="center">Phone</StyledTableCell>
            <StyledTableCell align="center">Role</StyledTableCell>
            <StyledTableCell align="center">Status</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => (
            <StyledTableRow key={index}>
              <StyledTableCell component="th" scope="row">
                {row.name}
              </StyledTableCell>
              <StyledTableCell align="center">{row.email}</StyledTableCell>
              <StyledTableCell align="center">{row.phone}</StyledTableCell>
              <StyledTableCell align="center">{row.role}</StyledTableCell>
              <StyledTableCell align="center">{row.status}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
