export const PaymentMethods = {
  CASH: "cash",
  CARD: "card",
  //TODO: Add more payment methods
} as const;

export const PaymentMethodLabels = {
  [PaymentMethods.CASH]: "Cash",
  [PaymentMethods.CARD]: "Card",
};

export const BooleanValuesForSelectOptions = {
  TRUE: "1",
  FALSE: "0",
} as const;

export const CustomerSubscriptionStatus = {
  ACTIVE: "ACTIVE",
  EXPIRED: "EXPIRED",
  CANCELLED: "CANCELLED",
};

export const LeadStatus = {
  INTERESTED: "INTERESTED",
  NOT_INTERESTED: "NOTINTERESTED",
  FOLLOW_UP: "FOLLOWUP",
  CONVERTED: "CONVERTED",
} as const;

export enum AppPermission {
  AllPermissions = "All Permissions",
  ViewBusiness = "View Business",
  CreateAndManageBusiness = "Create & Manage Business",
  ViewPlans = "View Plans",
  CreateAndUpdatePlans = "Create & Update Plans",
  ViewTeamMembers = "View Team Members",
  ManageTeamMembers = "Manage Team Members",
  ViewCustomers = "View Customers",
  ManageCustomers = "Manage Customers",
  ViewCustomerSubscriptions = "View Customer Subscriptions",
  ManageCustomerSubscriptions = "Manage Customer Subscriptions",
  CreateAndManageLeads = "Create & Manage Leads",
  ViewLeads = "View Leads",
}
