import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  List,
} from "@mui/material";

export const ConfirmCancelMembershipDialog = ({
  isOpen,
  onClose,
  onCancelMembership,
}: {
  isOpen: boolean;
  onClose: () => void;
  onCancelMembership: () => void;
}) => {
  return (
    <Dialog
      onClose={(event, reason) => {
        if (reason === "backdropClick") return;
        onClose();
      }}
      open={isOpen}
    >
      <DialogTitle>Cancel Membership</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to cancel this membership?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button size="medium" onClick={onClose}>
          Close
        </Button>
        <Button
          color="error"
          size="medium"
          variant="contained"
          onClick={onCancelMembership}
          autoFocus
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};
