import { AppPermission } from "lib/constants";

function isPermissionAllowed({
  action,
  permissions = [],
}: {
  action: AppPermission;
  permissions: AppPermission[];
}) {
  const index = permissions.indexOf(action);

  const allPermissions = permissions.includes(AppPermission.AllPermissions);

  return index > -1 || allPermissions;
}

export function isAllowed({
  actions,
  permissions,
  type = "ALL",
}: {
  permissions: AppPermission[];
  actions: AppPermission[];
  type?: "ALL" | "ANY";
}) {
  const actionsAllowed = actions.map((action) => {
    return isPermissionAllowed({ action, permissions });
  });

  if (type === "ALL") {
    return actionsAllowed.every((allowed) => allowed);
  }

  if (type === "ANY") {
    return actionsAllowed.some((allowed) => allowed);
  }

  return true;
}
