import { Box, Typography } from "@mui/material";
import React from "react";

interface Props {
  label: string;
  value: React.ReactNode;
}

export const Field = ({ label, value }: Props) => {
  return (
    <Box width="100%">
      <Typography variant="body2" color="textSecondary" gutterBottom>
        {label}
      </Typography>
      <Typography noWrap variant="body1" width="100%">
        {value}
      </Typography>
    </Box>
  );
};
