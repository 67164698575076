import axios, { AxiosError, AxiosResponse, HttpStatusCode } from "axios";
import { ErrorMessages, AppEvents } from "data";
import globals from "./globals";

/**
 * Success -
 * {
 *  success: true,
 *  data: {}
 * }
 *
 * Error -
 * {
 *  success: false,
 *  error: Some error message
 */

const defaultBaseUrl = globals.get("apiHost");

const axiosFetch = axios.create({
  baseURL: defaultBaseUrl,
  withCredentials: true,
  headers: {
    common: {
      "Content-Type": "application/json",
    },
  },
});

function successRespInterceptor(response: AxiosResponse<any, any>) {
  const { data } = response;

  if (!data.success) {
    return Promise.reject(data);
  }

  return data;
}

function unAuthorizedRespInterceptor(error: AxiosError<any, any>) {
  // TODO: fix any type
  if ((error.config as any)?.skipExpiredSessionCheck) {
    return Promise.reject({
      error: "Unauthorized",
    });
  }

  return new Promise((resolve, reject) => {
    window.dispatchEvent(new CustomEvent(AppEvents.NOT_AUTHENTICATED));
    reject({
      error: "Unauthorized",
    });
  });
}

function normalizeErrorRespInterceptor(error: AxiosError<any>) {
  if (!error.response) {
    return Promise.reject({
      error: ErrorMessages.NETWORK_ERROR,
    });
  }

  const errorMessage = error.response.data?.error;

  return Promise.reject({
    error: errorMessage ? errorMessage : error.toString(),
  });
}

// Expected success: false in response
function failedRespInterceptor(error: AxiosError<any>) {
  if (error.response && error.response.status === HttpStatusCode.Unauthorized) {
    return unAuthorizedRespInterceptor(error);
  }

  return normalizeErrorRespInterceptor(error);
}

axiosFetch.interceptors.response.use(
  successRespInterceptor,
  failedRespInterceptor
);

export { axiosFetch };
