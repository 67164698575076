import { useCallback, useState } from "react";
import {
  AppBar,
  Box,
  Dialog,
  IconButton,
  Link,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { DragAndDrop } from "ui/components/DragAndDrop/DragAndDrop";
import { Preview } from "app/modules/Members/BulkImport/Preview";
import { processCustomerFile } from "app/modules/Members/BulkImport/helpers";
import { useAppSnackbar } from "lib/contexts/SnackbarContext";
import { SelectedMemberDetails } from "app/modules/Members/BulkImport/types";
import CloseIcon from "@mui/icons-material/Close";

export const BulkImportDialog = ({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const theme = useTheme();
  const onlySmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [file, setFile] = useState<File | null>(null);
  const [customerData, setCustomerData] = useState<
    SelectedMemberDetails[] | null
  >(null);

  const snackbar = useAppSnackbar();

  const handleDrop = async (acceptedFiles: File[]) => {
    if (acceptedFiles.length === 0) return;
    setFile(acceptedFiles[0]);

    try {
      const { customers } = await processCustomerFile(acceptedFiles[0]);
      setCustomerData(customers);
    } catch (error: unknown) {
      if (typeof error === "string") {
        snackbar.show(error, { variant: "error" });
      }

      if (error instanceof Error) {
        snackbar.show(error.message, { variant: "error" });
      }
    }
  };

  const handleFileDelete = useCallback(() => {
    setFile(null);
    setCustomerData(null);
  }, []);

  const isFileSelected = customerData !== null && file !== null;

  return (
    <Dialog open={open} onClose={onClose} fullScreen>
      <Box>
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={onClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Bulk Import Customers
            </Typography>
          </Toolbar>
        </AppBar>
        {isFileSelected ? (
          <Preview
            onSuccess={() => {
              onClose();
              setFile(null);
            }}
            file={file}
            customerData={customerData}
            onDelete={handleFileDelete}
          />
        ) : (
          <Box
            p={9}
            flexDirection="column"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <DragAndDrop
              size={onlySmallScreen ? "small" : "medium"}
              onDrop={handleDrop}
            />
            <Link
              marginTop={1}
              href={process.env.PUBLIC_URL + "/assets/template.xlsx"}
              target="_blank"
            >
              Download template
            </Link>
          </Box>
        )}
      </Box>
    </Dialog>
  );
};
