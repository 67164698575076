import { CustomerSubscriptionStatus } from "lib/constants";
import { PlanList } from "services/types/plan.types";
import { Plan } from "types/Plan";
import { SubscriptionWithPlanDetails } from "types/Subscription";

/**
 *
 * @param customerSubscriptions All subscriptions of a customer
 * @returns Active subscription of the customer. If no active subscription found, returns undefined. Assumes that there is only one active subscription at a time.
 */
export const getCustomerActiveSubscription = (
  customerSubscriptions: SubscriptionWithPlanDetails[]
) => {
  return customerSubscriptions.find(
    (subscription) => subscription.status === CustomerSubscriptionStatus.ACTIVE
  );
};

export const getCustomerInActiveSubscriptions = (
  customerSubscriptions: SubscriptionWithPlanDetails[]
) => {
  return customerSubscriptions.filter(
    (subscription) => subscription.status !== CustomerSubscriptionStatus.ACTIVE
  );
};

export const getPlanDetails = (
  plans: PlanList,
  planId: string
): Plan | undefined => {
  if (isNaN(parseInt(planId, 10))) return undefined;

  return plans.find((plan) => plan.id === parseInt(planId));
};
