import { LoadingButton } from "@mui/lab";
import {
  Box,
  Dialog,
  FormControl,
  FormHelperText,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { selectSelectedBusiness } from "app/app.slice";
import { IntervalUnit } from "app/modules/Plans/AddPlan/constants";
import { useAddPlan } from "app/modules/Plans/AddPlan/useAddPlan";
import { Dayjs } from "dayjs";
import { useAppSelector } from "lib/store/hooks";
import { toTitleCase } from "lib/utils/toTitleCase";
import { ActionableTitleContentCard } from "ui/atoms/ActionableTitleContentCard";

export const AddPlanDialog = ({
  onClose,
  open,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const { formik } = useAddPlan({ onClose });

  const business = useAppSelector(selectSelectedBusiness);

  const currency = business?.currency;

  const handleStartDateChange = (date: Dayjs | null) => {
    formik.setFieldValue("startDate", date);
  };

  const handleEndDateChange = (date: Dayjs | null) => {
    formik.setFieldValue("endDate", date);
  };

  return (
    <Dialog maxWidth="md" open={open} onClose={onClose} fullWidth>
      <Box component="form" onSubmit={formik.handleSubmit}>
        <ActionableTitleContentCard>
          <ActionableTitleContentCard.Title>
            Plan Details
          </ActionableTitleContentCard.Title>

          <ActionableTitleContentCard.Content>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <TextField
                  name="name"
                  label="Name"
                  variant="outlined"
                  type="text"
                  fullWidth
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  name="description"
                  label="Description"
                  variant="outlined"
                  type="text"
                  fullWidth
                  value={formik.values.description}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.description &&
                    Boolean(formik.errors.description)
                  }
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl
                  fullWidth
                  error={
                    formik.touched.minPrice && Boolean(formik.errors.minPrice)
                  }
                >
                  <InputLabel>Minimum Price</InputLabel>
                  <OutlinedInput
                    name="minPrice"
                    label="Minimum Price"
                    type="number"
                    startAdornment={
                      <InputAdornment position="start">
                        {currency}
                      </InputAdornment>
                    }
                    value={formik.values.minPrice}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  <FormHelperText>
                    {formik.touched.minPrice && formik.errors.minPrice}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl
                  fullWidth
                  error={
                    formik.touched.maxPrice && Boolean(formik.errors.maxPrice)
                  }
                >
                  <InputLabel>Maximum Price</InputLabel>
                  <OutlinedInput
                    name="maxPrice"
                    label="Maximum Price"
                    type="number"
                    startAdornment={
                      <InputAdornment position="start">
                        {currency}
                      </InputAdornment>
                    }
                    value={formik.values.maxPrice}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  <FormHelperText>
                    {formik.touched.maxPrice && formik.errors.maxPrice}
                  </FormHelperText>
                </FormControl>
              </Grid>

              <Grid
                item
                xs={12}
                md={6}
                display="flex"
                justifyContent="space-between"
              >
                <TextField
                  name="intervalCount"
                  label="Duration"
                  variant="outlined"
                  type="number"
                  sx={{ width: "62%" }}
                  value={formik.values.intervalCount}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.intervalCount &&
                    Boolean(formik.errors.intervalCount)
                  }
                />
                <FormControl
                  sx={{ width: "35%" }}
                  error={
                    !!formik.touched.intervalUnit &&
                    !!formik.errors.intervalUnit
                  }
                >
                  <InputLabel>Duration Period</InputLabel>
                  <Select
                    name="intervalUnit"
                    label="Duration Period"
                    value={formik.values.intervalUnit}
                    defaultValue="INR"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.intervalUnit &&
                      Boolean(formik.errors.intervalUnit)
                    }
                  >
                    {Object.values(IntervalUnit).map((value, index) => {
                      return (
                        <MenuItem key={index} value={value}>
                          {toTitleCase(value)}
                        </MenuItem>
                      );
                    })}
                  </Select>
                  <FormHelperText>
                    {formik.touched.intervalUnit && formik.errors.intervalUnit}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <DatePicker
                  name="startDate"
                  label="Start Date"
                  sx={{ width: "100%" }}
                  value={formik.values.startDate}
                  onChange={handleStartDateChange}
                  slotProps={{
                    textField: {
                      error: !!formik.errors.startDate,
                      helperText: formik.errors.startDate,
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <DatePicker
                  name="endDate"
                  label="End Date"
                  sx={{ width: "100%" }}
                  value={formik.values.endDate}
                  onChange={handleEndDateChange}
                  slotProps={{
                    textField: {
                      error: !!formik.errors.endDate,
                      helperText: formik.errors.endDate,
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl
                  fullWidth
                  error={
                    !!formik.touched.isRecurring && !!formik.errors.isRecurring
                  }
                >
                  <InputLabel>Recurring</InputLabel>
                  <Select
                    name="isRecurring"
                    label="Recurring"
                    fullWidth
                    value={formik.values.isRecurring}
                    defaultValue="INR"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.isRecurring &&
                      Boolean(formik.errors.isRecurring)
                    }
                  >
                    <MenuItem value="1">Yes</MenuItem>
                    <MenuItem value="0">No</MenuItem>
                  </Select>
                  <FormHelperText>
                    {formik.touched.isRecurring && formik.errors.isRecurring}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl
                  fullWidth
                  error={
                    !!formik.touched.isRegistrationFeeRequired &&
                    !!formik.errors.isRegistrationFeeRequired
                  }
                >
                  <InputLabel>Registration Fee Required</InputLabel>
                  <Select
                    name="isRegistrationFeeRequired"
                    label="Registration Fee Required"
                    fullWidth
                    value={formik.values.isRegistrationFeeRequired}
                    defaultValue="INR"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.isRegistrationFeeRequired &&
                      Boolean(formik.errors.isRegistrationFeeRequired)
                    }
                  >
                    <MenuItem value="1">Yes</MenuItem>
                    <MenuItem value="0">No</MenuItem>
                  </Select>
                  <FormHelperText>
                    {formik.touched.isRegistrationFeeRequired &&
                      formik.errors.isRegistrationFeeRequired}
                  </FormHelperText>
                </FormControl>
              </Grid>
            </Grid>
          </ActionableTitleContentCard.Content>
          <ActionableTitleContentCard.Actions>
            <LoadingButton
              type="submit"
              variant="contained"
              size="large"
              loading={formik.isSubmitting}
            >
              <span>Add Plan</span>
            </LoadingButton>
          </ActionableTitleContentCard.Actions>
        </ActionableTitleContentCard>
      </Box>
    </Dialog>
  );
};
