import ReactConfetti from "react-confetti";
import React, { useState } from "react";
import { CustomerWithMemberAndMemberDetails } from "services/types/member.types";
import { Button } from "@mui/material";
import { PaymentRecordForm } from "app/modules/Billing/RecordPayment/PaymentRecordForm";
import { SubscriptionWithPlanDetails } from "types/Subscription";

export const RecordPaymentAction = ({
  selectedCustomer,
  selectedSubscription,
}: // TODO: Refresh the subscription details after payment is recorded
{
  selectedCustomer: CustomerWithMemberAndMemberDetails;
  selectedSubscription: SubscriptionWithPlanDetails;
}) => {
  const [isPaymentDialogOpen, setIsPaymentDialogOpen] = useState(false);
  const [isConfettiActive, setIsConfettiActive] = useState(false);

  return (
    <React.Fragment>
      <Button
        type="submit"
        variant="outlined"
        size="large"
        color="primary"
        onClick={() => setIsPaymentDialogOpen(true)}
      >
        Record Payment
      </Button>

      <PaymentRecordForm
        customerId={selectedCustomer.id}
        customerSubscriptionId={selectedSubscription.id}
        memberId={selectedCustomer.member.id}
        dueAmount={selectedSubscription.dueAmount}
        open={isPaymentDialogOpen}
        onSuccess={() => setIsConfettiActive(true)}
        onClose={() => setIsPaymentDialogOpen(false)}
      />
      <ReactConfetti
        run={isConfettiActive}
        recycle={false}
        tweenDuration={700}
        onConfettiComplete={() => setIsConfettiActive(false)}
      />
    </React.Fragment>
  );
};
