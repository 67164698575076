import { LoadingButton } from "@mui/lab";
import {
  Box,
  Dialog,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useAddMemberForm } from "app/modules/Members/AddMember/useMemberForm";
import { Dayjs } from "dayjs";
import { ActionableTitleContentCard } from "ui/atoms/ActionableTitleContentCard";

//TODO: This is add customer basically. Add rank and name on invoice field in the form and send type as CUSTOMER in api request
//TODO: Change AddMember to AddCustomer and update member to customer everywhere in codebase to avoid confusion, in presentation layer Customer will be referred as Member

export const AddMemberForm = ({
  open = false,
  onClose,
}: {
  open?: boolean;
  onClose: () => void;
}) => {
  const { formik } = useAddMemberForm({
    onSuccess: () => {
      onClose();
    },
  });

  const handleDateOfBirthChange = (date: Dayjs | null) => {
    formik.setFieldValue("dob", date);
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <Box component="form" onSubmit={formik.handleSubmit}>
        <ActionableTitleContentCard>
          <ActionableTitleContentCard.Title>
            Member Details
          </ActionableTitleContentCard.Title>

          <ActionableTitleContentCard.Content>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <TextField
                  name="name"
                  label="Name"
                  variant="outlined"
                  type="text"
                  fullWidth
                  autoFocus
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  name="email"
                  label="Email"
                  variant="outlined"
                  type="email"
                  fullWidth
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  name="phone"
                  label="Phone Number"
                  variant="outlined"
                  fullWidth
                  value={formik.values.phone}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.phone && Boolean(formik.errors.phone)}
                  helperText={formik.touched.phone && formik.errors.phone}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl
                  error={!!formik.touched.gender && !!formik.errors.gender}
                  fullWidth
                >
                  <InputLabel>Gender</InputLabel>
                  <Select
                    name="gender"
                    label="Gender"
                    fullWidth
                    value={formik.values.gender}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.gender && Boolean(formik.errors.gender)
                    }
                  >
                    <MenuItem value="male">Male</MenuItem>
                    <MenuItem value="female">Female</MenuItem>
                    <MenuItem value="other">Other</MenuItem>
                  </Select>
                  <FormHelperText>
                    {formik.touched.gender && formik.errors.gender}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  name="address"
                  label="Address"
                  variant="outlined"
                  type="text"
                  multiline
                  fullWidth
                  value={formik.values.address}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.address && Boolean(formik.errors.address)
                  }
                  helperText={formik.touched.address && formik.errors.address}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <DatePicker
                  name="dob"
                  label="Date Of Birth"
                  sx={{ width: "100%" }}
                  value={formik.values.dob}
                  onChange={handleDateOfBirthChange}
                  slotProps={{
                    textField: {
                      error: !!formik.errors.dob,
                      helperText: formik.errors.dob,
                    },
                  }}
                />
              </Grid>
            </Grid>
          </ActionableTitleContentCard.Content>
          <ActionableTitleContentCard.Actions>
            <LoadingButton
              type="submit"
              variant="contained"
              size="large"
              loading={formik.isSubmitting}
            >
              <span>Save</span>
            </LoadingButton>
          </ActionableTitleContentCard.Actions>
        </ActionableTitleContentCard>
      </Box>
    </Dialog>
  );
};
