import {
  Box,
  Grid,
  Typography,
  Dialog,
  Slide,
  AppBar,
  Toolbar,
  IconButton,
  Tabs,
  Tab,
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import React, { useState } from "react";
import { CustomerWithMemberAndMemberDetails } from "services/types/member.types";
import CloseIcon from "@mui/icons-material/Close";
import { MemberDetailsForm } from "app/modules/Members/ViewMember/MemberDetailsForm";
import { ViewMembership } from "app/modules/Members/ViewMembership/ViewMembership";
import { MemberSummary } from "app/views/MemberSummary";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ pt: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export const ViewMemberDetails = ({
  selectedCustomer,
  setSelectedCustomer,
}: {
  selectedCustomer: CustomerWithMemberAndMemberDetails | null;
  setSelectedCustomer: (a: undefined) => void;
}) => {
  const [tab, setTab] = useState(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };

  if (!selectedCustomer) return null;

  const onClose = () => {
    setSelectedCustomer(undefined);
  };

  return (
    <React.Fragment>
      <Dialog
        fullScreen
        onClose={onClose}
        TransitionComponent={Transition}
        open={Boolean(selectedCustomer)}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={onClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {selectedCustomer?.member.memberDetails.name}
            </Typography>
          </Toolbar>
        </AppBar>
        <Box padding={{ xs: 2, sm: 3 }} width="100%">
          <Grid container spacing={3}>
            <Grid item xs={12} md={3}>
              <MemberSummary details={selectedCustomer.member} />
            </Grid>
            <Grid item xs={12} md={9}>
              <Tabs
                value={tab}
                onChange={handleTabChange}
                aria-label="basic tabs example"
              >
                <Tab label="Details" {...a11yProps(0)} />
                <Tab label="Memberships" {...a11yProps(1)} />
              </Tabs>
              <CustomTabPanel value={tab} index={0}>
                <MemberDetailsForm selectedCustomer={selectedCustomer} />
              </CustomTabPanel>
              <CustomTabPanel value={tab} index={1}>
                <ViewMembership selectedCustomer={selectedCustomer} />
              </CustomTabPanel>
            </Grid>
          </Grid>
        </Box>
      </Dialog>
    </React.Fragment>
  );
};
