import { PaymentMetrics } from "services/types/payment.types";
import { TagTypesMap } from "services/constants";
import { apiSlice } from "./api.slice";

const paymentsSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getPaymentMetrics: builder.query<PaymentMetrics, void>({
      query: () => ({
        url: "/payments/metrics",
        method: "GET",
      }),
      providesTags: [TagTypesMap.SubscriptionPayments],
    }),
  }),
});

export const { useGetPaymentMetricsQuery } = paymentsSlice;
