import {
  Box,
  Button,
  Divider,
  IconButton,
  List,
  Toolbar,
  Typography,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { sidebarModulesList } from "app/modules";
import { useGetBusiness } from "lib/hooks/useGetBusiness";
import React from "react";
import { SwitchBusinessDialog } from "ui/components/Sidebar/SwitchBusinessDialog";

export const SidebarDrawer = ({
  handleDrawerClose,
}: {
  handleDrawerClose?: () => void;
}) => {
  const { businesses, selectedBusiness } = useGetBusiness();

  const [isSelectBusinessDialogOpen, setIsSelectBusinessDialogOpen] =
    React.useState(false);

  const handleSelectBusinessDialogOpen = () => {
    setIsSelectBusinessDialogOpen(true);
  };

  const handleSelectBusinessDialogClose = () => {
    setIsSelectBusinessDialogOpen(false);
  };

  return (
    <Box>
      <Toolbar>
        <Box display="flex" alignItems="center" columnGap={1}>
          <Typography
            variant="body1"
            sx={{
              fontWeight: 600,
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              overflow: "hidden",
              maxWidth: 170,
            }}
          >
            {selectedBusiness?.name || "-"}
          </Typography>
          {businesses.length > 1 ? (
            <IconButton onClick={handleSelectBusinessDialogOpen} size="small">
              <KeyboardArrowDownIcon />
            </IconButton>
          ) : null}
        </Box>
      </Toolbar>
      <Divider />
      <List>
        {sidebarModulesList.map((Component, index) => (
          <Component key={index} handleDrawerClose={handleDrawerClose} />
        ))}
      </List>

      <SwitchBusinessDialog
        isOpen={isSelectBusinessDialogOpen}
        onClose={handleSelectBusinessDialogClose}
      />
    </Box>
  );
};
