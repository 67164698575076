import { selectIsLoggedIn, selectSelectedBusinessId } from "app/app.slice";
import { useAppSelector } from "lib/store/hooks";
import {
  getShouldNavigateToDashboard,
  getShouldNavigateToLogin,
} from "app/Routes/helpers";

export const useShouldNavigate = () => {
  const isLoggedIn = useAppSelector(selectIsLoggedIn);
  const selectedBusinessId = useAppSelector(selectSelectedBusinessId);

  const shouldNavigateToLogin = getShouldNavigateToLogin({ isLoggedIn });
  const shouldNavigateToDashboard = getShouldNavigateToDashboard({
    isLoggedIn,
    selectedBusinessId,
  });

  return {
    shouldNavigateToLogin,
    shouldNavigateToDashboard,
    shouldNavigateToCreateBusiness:
      !shouldNavigateToLogin && !shouldNavigateToDashboard,
  };
};
