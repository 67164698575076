import { Paths } from "data";
import { useFormik } from "formik";
import { useAppSnackbar } from "lib/contexts/SnackbarContext";
import { isEmail } from "lib/utils/validators/isEmail";
import { isPhoneNumber } from "lib/utils/validators/isPhoneNumber";
import { useNavigate } from "react-router-dom";
import { useRegisterMutation } from "services/auth.slice";

const validate = (values: {
  email: string;
  password: string;
  name: string;
  phone: string;
}) => {
  const errors: {
    email?: string;
    password?: string;
    name?: string;
    phone?: string;
  } = {};

  if (!values.email) {
    errors.email = "Email is required";
  } else if (!isEmail(values.email)) {
    errors.email = "Invalid email address";
  }

  if (!values.password) {
    errors.password = "Password is required";
  }

  if (!values.name) {
    errors.name = "Name is required";
  }

  if (!values.phone) {
    errors.phone = "Phone Number is required";
  } else if (!isPhoneNumber(values.phone)) {
    errors.phone = "Invalid Mobile Number";
  }

  return errors;
};

export const useRegisterForm = () => {
  const [register] = useRegisterMutation();

  const snackbar = useAppSnackbar();

  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      name: "",
      phone: "",
      password: "",
      email: "",
    },
    validate,
    onSubmit: async (values) => {
      const response = await register({
        email: values.email,
        password: values.password,
        name: values.name,
        phone: values.phone,
      });

      if ("data" in response) {
        snackbar.show("Registration Successful", {
          variant: "success",
        });
        navigate(Paths.AUTH.LOGIN);
      } else if ("error" in response) {
        snackbar.show(response.error as string, {
          variant: "error",
          preventDuplicate: true,
        });
      }
    },
  });

  return { formik };
};
